// @ts-ignore
import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
// @ts-ignore
import { MODAL_SIZE, showConfirmWithException } from '@UIkit/components/modal';
// @ts-ignore
import { createModalForm } from '@UIkit/components/panels';
// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
// @ts-ignore
import { createExceptionBlock, EXCEPTION_BLOCK_CLS } from '@UIkit/components/blocks/ExceptionBlock';
import { createModulePanel } from '@Components/panels';
import { createGridActionBar, createGridCheckboxSelectionModel } from '@Components/grid';
import { forSignFilterMethods } from './filter';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { EW_GRID_COMMON_COLUMN_CONFIG_NAME } from '@Ediweb/columns';
import { documentsForSignApi } from '@Ediweb/modules/documentsForSign/rest';
import { GridActionConfig } from '@Ediweb/modules/documentsForSign/definitions';
import { EWDocumentsGridModule } from '@Ediweb/modules/documentGrids/documents';
import { showErrorToast } from '@Ediweb/core';
import { createComboCerts } from '@Ediweb/components/ComboboxCerts/ComboboxCerts';
import { documentsModal } from '@Edi/methods/methods';
import { CertificateHandler, PreselectedCertObject } from '@App/js/signProcess';

export class DocumentsForSign extends EWDocumentsGridModule {
	moduleData: ModuleData<DocumentHeader>;
	maxSignLimit: number = 100;
	signAttemptsCount = 2;
	currentAttempt = 0;
	_isSignAll: boolean = false;
	_isSignInProcess: boolean = false;
	_processedRecordsMap: Map<DocumentHeader['id'], ExtRecord<DocumentHeader>> = new Map();
	_signedRecords: ExtRecord<DocumentHeader>[] = [];
	_notSignedRecords: ExtRecord<DocumentHeader>[] = [];
	_selectedRecords: ExtRecord<DocumentHeader>[] = [];
	_selectedRecordsMap: Map<DocumentHeader['id'], ExtRecord<DocumentHeader>> = new Map();
	_refreshHandler: DocumentsForSign['refreshHandler'];
	_fireSearch: DocumentsForSign['fireSearch'];
	modulePanel: ExtComponent;
	grid: ExtComponent;
	filterForm: ExtComponent;
	filterObject: AnyObject;
	totalsLabel: ExtComponent;
	signProperties: AnyObject;
	preselectedCert: PreselectedCertObject | undefined;

	constructor() {
		super();
		const me: DocumentsForSign = this;
		me._refreshHandler = me.refreshHandler.bind(me);
		me._fireSearch = me.fireSearch.bind(me);
	}

	init(data: ModuleData<DocumentHeader>, initCallBack: Function): () => boolean {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	onRender() {
		const me = this;
		edi.events.documents.on('create', me._refreshHandler);
		edi.events.documents.on('change', me._refreshHandler);
	}

	setModuleLoading(loader: boolean | string) {
		const me = this;
		if (me.moduleData.tab && !me.moduleData.tab.isDestroyed) {
			me.moduleData.tab.setLoading(loader);
		}
	}

	refreshHandler() {
		const me = this;
		const store = me.grid.getStore();
		const gridSelModel = me.grid.getSelectionModel();
		if (gridSelModel) {
			gridSelModel.deselectAll();
		}
		store.reload();
	}

	fireSearch() {
		const me = this;
		me.filterObject.filter();
		//т.к. фильтр изменился, то сбросим ранее выбранные строки
		//можно навернуть конечно проверку по типам и статусам для запомненных строк,
		//но оставим это на случай "если попросят"
		me.clearSelectedRecords();
	}

	createFilterObject(): DocumentsForSign['filterObject'] {
		const me = this;
		return edi.filters.createGridFilter(
			documentsForSignApi.GET_LIST,
			me.filterForm,
			me.grid,
			forSignFilterMethods.createArgs,
			{ gridName: 'outgoing' },
			{
				persistence: {
					enabled: true,
					name: `${me.moduleData.modName}_docs_grid`
				}
			}
		);
	}

	createFilterForm(): DocumentsForSign['filterForm'] {
		const me = this;
		const { formItemsMap, items } = forSignFilterMethods.createFilterItems();
		return createModuleFilterForm(
			{
				formItemsMap: formItemsMap,
				items: items,
				toggleAutoSearch: true,
				autosearchEnabled: false,
				searchOnFilterCollapse: true,
				setFormDefaults: function () {
					me.filterForm.formItemsMap['docType'].setValue(forSignFilterMethods.getFilterDoctypes());
					me.filterForm.formItemsMap['state'].setValue(forSignFilterMethods.getFilterStates());
				}
			},
			me._fireSearch
		) as ExtComponent;
	}

	createDetailsColumnAction(): AnyObject {
		return {
			glyph: edi.constants.ICONS.DETAILS,
			iconCls: 'edi-grid-row-button-details',
			testCls: 'test-action-icon-details',
			handler: function (
				_view: ExtComponent,
				_rowIndex: number,
				_colindex: number,
				_actionItem: unknown,
				_event: unknown,
				record: ExtRecord<DocumentHeader>
			) {
				let recordData = record.getData();
				edi.document.actions.openDetailsModule(recordData.type, recordData);
			}
		};
	}

	createActionColumn(): AnyObject {
		const me = this;
		return createActionsColumnConfig({
			items: [me.createDetailsColumnAction()]
		});
	}

	getRecordsBatchForSign(source: 'current' | 'first' | 'next'): Promise<ExtRecord<DocumentHeader>[]> {
		const me = this;
		return new Promise((resolve) => {
			if (source === 'current') {
				resolve(me._selectedRecords);
			} else {
				if (source === 'first') {
					me.setFirstPage();
				} else if (source === 'next') {
					me.setNextPage();
				}
				me.grid.getStore().on(
					'load',
					function () {
						resolve(me.grid.getStore().getRange());
					},
					me,
					{ single: true }
				);
			}
		});
	}

	isLastPage(): boolean {
		const me = this;
		const { currentPage, pageCount } = me.grid.pagingBar.getPageData();
		return currentPage === pageCount;
	}

	setNextPage() {
		const me = this;
		me.grid.pagingBar.moveNext();
	}

	setFirstPage() {
		const me = this;
		me.grid.pagingBar.moveFirst();
	}

	showSignResultsWindow() {
		const me = this;
		documentsModal(
			{
				title: edi.i18n.getMessage('documents.not.processed.title'),
				width: MODAL_SIZE.widthLarge,
				model: 'DOCUMENTS_MODAL',
				columns: edi.columns.get('documents_modal_with_error'),
				items: [
					createModalForm({
						items: [
							createLabel({
								typography: 'body-short_01',
								html: edi.utils.formatString(edi.i18n.getMessage('sign.extended.result.totals'), [
									me._processedRecordsMap.size
								])
							}),
							createGrid({
								store: edi.stores.createMemoryStore(me._signedRecords, 'DOCUMENTS_MODAL'),
								gridConfig: {
									title: edi.i18n.getMessage('sign.extended.result.signed', [
										me._signedRecords.length
									]),
									cls: 'ui-documents-list',
									maxHeight: 360,
									disablePaging: true,
									columns: edi.columns.get('documents_modal'),
									hideSettingsButton: true
								}
							}),
							createGrid({
								store: edi.stores.createMemoryStore(me._notSignedRecords, 'DOCUMENTS_MODAL'),
								gridConfig: {
									cls: 'ui-documents-list',
									title: edi.i18n.getMessage('sign.extended.result.notsigned', [
										me._notSignedRecords.length
									]),
									maxHeight: 360,
									disablePaging: true,
									columns: edi.columns.get('documents_modal_with_error'),
									hideSettingsButton: true
								}
							})
						]
					})
				]
			},
			me._notSignedRecords,
			me.moduleData
		);
	}

	collectProcessedRecords(signedRecords: ExtRecord<DocumentHeader>[], notSignedRecords: ExtRecord<DocumentHeader>[]) {
		const me = this;
		const successfulRecords = signedRecords.filter((rec) => rec.successfullySigned === true);
		const failedRecords = notSignedRecords.concat(
			signedRecords.filter((rec) => rec.errorData || rec.errorResponse)
		);
		successfulRecords.forEach((record) => {
			record.data.signState = 'success';
			me._processedRecordsMap.set(record.get('id'), record);
			me._signedRecords.push(record);
		});

		failedRecords.forEach((record) => {
			const recId = record.get('id');
			if (!me._processedRecordsMap.get(recId)) {
				const { permissionsPassed } = record.checkResult ?? {};
				if (record.errorData || record.errorResponse) {
					record.errorData = record.errorData || record.errorResponse;
				} else if (!permissionsPassed) {
					record.errorData = { typeError: 'documents.action.tooltip.not_enough_permissions' };
				} else {
					record.errorData = { typeError: 'error.not.processed.wrong.state' };
				}

				record.data.signState = 'error';
				record.data.error = edi.utils.formatComplexServerError(record.errorData);
				me._processedRecordsMap.set(recId, record);
				me._notSignedRecords.push(record);
			}
		});
	}

	clearSelectedCertAndPoa() {
		const me = this;
		super.clearSelectedCertAndPoa();
		//делаем очистку тут, т.к. отмена процесса подписания так же должна все сбрасывать как и шататное заверщение
		me._isSignInProcess = false;
		me.currentAttempt = 0;
		me._processedRecordsMap = new Map();
		me._signedRecords = [];
		me._notSignedRecords = [];
	}

	completeSignProcess() {
		const me = this;
		me.clearSelectedRecords();
		me.setFirstPage();
		me.showSignResultsWindow();
		me._signCompleteCallback();
	}

	async batchCompleteHandler() {
		const me = this;
		if (me._isSignAll) {
			if (me._signedRecords.length >= me.maxSignLimit) {
				me.completeSignProcess();
			} else if (!me.isLastPage()) {
				const records = await me.getRecordsBatchForSign('next');
				me.signDocs(records);
			} else {
				//в случае когда у нас несколько страниц, то во время подписания при переходе по страницам часть записей
				//может "перепрыгнуть" на прошлую страницу из-за смещения выборки, поэтому перепроверим еще раз с начала
				const needToRecheck = me.grid.pagingBar.getPageData().pageCount > 1;
				if (needToRecheck && me.currentAttempt < me.signAttemptsCount) {
					me.startSignProcess();
				} else {
					me.completeSignProcess();
				}
			}
		} else {
			me.completeSignProcess();
		}
	}

	signDocs(records: ExtRecord<DocumentHeader>[]) {
		const me = this;
		me._isSignInProcess = true;
		me.signProperties.preselectedCert = me.preselectedCert;
		edi.document.actions.documentGridActionProcess({
			grid: me.grid,
			initialData: records,
			data: records,
			moduleData: me.moduleData,
			action: edi.constants.DOCUMENT_ACTIONS.SIGN,
			handler: me._signActionHandler,
			beforeStart: me._beforeSignStart,
			loadingMessage: 'document.sign.started',
			forceCompleteEvent: true,
			dontShowNotProccessed: true,
			skipInfoAndConfirm: true,
			completeCallback: function (
				signedRecords: ExtRecord<DocumentHeader>[],
				notSignedRecords: ExtRecord<DocumentHeader>[]
			) {
				me.collectProcessedRecords(signedRecords, notSignedRecords);
				me.batchCompleteHandler();
			},
			noChangesCallback: function (notSignedRecords: ExtRecord<DocumentHeader>[]) {
				me.collectProcessedRecords([], notSignedRecords);
				me.batchCompleteHandler();
			}
		});
	}

	signActionHandler(
		record: ExtRecord<DocumentHeader>,
		grid: ExtComponent,
		callback: Function,
		failure: Function,
		optionsOrModuleData: AnyObject | DocumentsForSign['moduleData'],
		defaultEventObj?: unknown,
		additionalHandler?: unknown,
		getProcessorState?: () => {
			signedDocs: ExtRecord<DocumentHeader>[];
			notSignedDocs: ExtRecord<DocumentHeader>[];
		}
	) {
		const me = this;
		const originalHandler = super.signActionHandler(...arguments);
		return function () {
			//перед тем как выполнять "подписание" очередного документа из пачки, проверим что лимит не исчерпан
			// лишние документы пропускаем ignored = true, что бы процесс дошел до конца и завершился штатно
			const state = getProcessorState && getProcessorState();
			const signedDocs = (state?.signedDocs ?? []).filter((rec) => rec.successfullySigned === true);
			const leastRecordsForSignCount = me.maxSignLimit - (signedDocs.length + me._signedRecords.length);
			if (leastRecordsForSignCount <= 0) {
				//ставим флаг что эти записи не обрабатывались, поэтому их не надо записывать в ошибочные
				record.ignored = true;
				'function' == typeof failure && failure(record, {});
				'function' == typeof callback && callback();
			} else {
				originalHandler();
			}
		};
	}

	async startSignProcess() {
		const me = this;
		me.currentAttempt++;
		const records = await me.getRecordsBatchForSign(me._isSignAll ? 'first' : 'current');
		me.signDocs(records);
	}

	showSignWindow() {
		const me = this;
		me.clearSelectedCertAndPoa();
		me.preselectedCert = undefined;

		const allRecordsCount = me.grid.getStore().getTotalCount();
		if (allRecordsCount === 0) {
			showErrorToast(null, edi.i18n.getMessage('documents_for_sign.error.no.documents'));
			return;
		}

		me._isSignAll = me._selectedRecords.length === 0;
		const recordsForSignCount = me._isSignAll ? allRecordsCount : me._selectedRecords.length;
		me._processedRecordsMap = new Map();
		me._signedRecords = [];
		me._notSignedRecords = [];

		let selectedRecordsText: string = edi.i18n.getMessage('sign.extended.modal.selected.count', [
			recordsForSignCount
		]);
		if (me._isSignAll && recordsForSignCount > me.maxSignLimit) {
			selectedRecordsText +=
				'<br><br>' + edi.i18n.getMessage('sign.extended.modal.limit.warning', [me.maxSignLimit]);
		}

		showConfirmWithException({
			title: edi.i18n.getMessage('sign.extended.modal.title'),
			exceptionType: 'warning',
			exceptionText: edi.i18n.getMessage('sign.extended.modal.warning'),
			msgText: selectedRecordsText,
			yesBtnConfig: {
				text: edi.i18n.getMessage('form.btn.sign'),
				itemId: 'yesBtn',
				disabled: true
			},
			success: function () {
				const modal: ExtComponent = this as ExtComponent;
				const certField: ExtComponent = modal.down('field[name="cert"]');
				const cert: CertificateHandler['_selectedCertificate'] = certField.getValue();
				const certObject: CertificateHandler['_selectedCertObj'] = certField.store
					.getRange()
					.find((rec: ExtRecord<AnyObject>) => rec.data.cert.objid === cert?.objid);
				if (cert && certObject) {
					me.preselectedCert = { cert, certObject };
					me.startSignProcess();
				}
			},
			noBtnConfig: {
				text: edi.i18n.getMessage('btn.cancel')
			},
			createItems: function () {
				const modal: ExtComponent = this as ExtComponent;
				modal.items = [
					createExceptionBlock({
						cls: EXCEPTION_BLOCK_CLS.inModal,
						exceptionType: modal.exceptionType,
						exceptionText: modal.exceptionText,
						text: modal.msgText
					}),
					createComboCerts({
						fieldLabel: edi.i18n.getMessage('certificate.title'),
						margin: '8 24 4 24',
						listeners: {
							change: function (cmp: ExtComponent, newValue: AnyObject | null) {
								const yesBtn: ExtComponent | null = modal.down('[itemId="yesBtn"]');
								yesBtn?.setDisabled(!newValue);
							}
						}
					})
				];
			}
		});
	}

	createSignActionCfg(): GridActionConfig {
		const me = this;
		return {
			id: edi.constants.DOCUMENT_ACTIONS.SIGN,
			name: edi.i18n.getMessage('document.sign.document'),
			notDisabled: true,
			handler: () => me.showSignWindow()
		};
	}

	actionsToolbarHandler(_actionId: GridActionConfig['id'], actionCfg: GridActionConfig) {
		if (typeof actionCfg.handler === 'function') {
			actionCfg.handler(actionCfg);
		}
	}

	createActionsToolbar(): ExtComponent {
		const me = this;
		return createGridActionBar({
			actionCfgs: [me.createSignActionCfg()],
			defaultHandler: me.actionsToolbarHandler.bind(me)
		}) as ExtComponent;
	}

	clearSelectedRecords() {
		const me = this;
		me._selectedRecords = [];
		me._selectedRecordsMap = new Map();
	}

	memoizeSelectedRecord(selModel: ExtComponent, selectedRecords: ExtRecord<DocumentHeader>[]) {
		const me = this;

		const recordsOnPage: ExtRecord<DocumentHeader>[] = me.grid.getStore().getRange();
		const recordsOnPageMap: Map<DocumentHeader['id'], ExtRecord<DocumentHeader>> = new Map();
		recordsOnPage.forEach((rec) => recordsOnPageMap.set(rec.get('id'), rec));

		me._selectedRecords = me._selectedRecords.filter((rec) => !recordsOnPageMap.get(rec.get('id')));
		me._selectedRecords.push(...selectedRecords);
		me._selectedRecordsMap = new Map();
		me._selectedRecords.forEach((rec) => me._selectedRecordsMap.set(rec.get('id'), rec));
	}

	selectionProcessor(selModel: ExtComponent, selected: ExtRecord<DocumentHeader>[], actionItems: ExtComponent[]) {
		const me = this;
		actionItems.forEach((btn) => {
			const actionCfg: GridActionConfig = btn.rulesData;
			if (actionCfg.id === edi.constants.DOCUMENT_ACTIONS.SIGN) {
				btn.setDisabled(me.grid.getStore().getRange().length === 0);
			} else {
				btn.setDisabled(selected.length === 0);
			}
		});
		me.memoizeSelectedRecord(selModel, selected);
		me.updateTotalsLabel();
	}

	updateTotalsLabel() {
		const me = this;
		me.totalsLabel.setText(
			edi.i18n.getMessage('sign.extended.totals', [
				me._selectedRecords.length,
				me.grid.getStore().getTotalCount()
			])
		);
	}

	createTotalsLabel(): DocumentsForSign['totalsLabel'] {
		const me = this;
		me.totalsLabel = createLabel({
			text: ''
		}) as ExtComponent;
		return me.totalsLabel;
	}

	getProxyConfig(): AnyObject {
		return {
			type: 'ajax',
			reader: {
				totalProperty: function (resp: AnyObject) {
					return resp?.data?.total;
				},
				rootProperty: function (resp: AnyObject) {
					return resp?.data?.items;
				}
			}
		};
	}

	getStoreConfig(): AnyObject {
		const me = this;
		return {
			pageSize: edi.constants.ITEMS_PER_PAGE,
			model: edi.models.getModel('DOCUMENT'),
			groupField: null,
			sortOnLoad: true,
			sorters: {
				property: 'creationDate',
				direction: 'DESC'
			},
			autoLoad: false,
			listeners: {
				datachanged: function (store: ExtComponent) {
					const recordsInSelected = store
						.getRange()
						.filter((rec: ExtRecord<DocumentHeader>) => me._selectedRecordsMap.get(rec.get('id')));
					me.grid.getSelectionModel().deselectAll();
					me.grid.getSelectionModel().select(recordsInSelected);
					me.updateTotalsLabel();
				}
			}
		};
	}

	getPagingBarConfig(): AnyObject {
		const me = this;
		return {
			pagingBarItems: [{ size: me.maxSignLimit }],
			itemsPerPage: me.maxSignLimit,
			totalsLabel: me.createTotalsLabel()
		};
	}

	getGridConfig(): AnyObject {
		const me = this;
		const columns = edi.columns.get(EW_GRID_COMMON_COLUMN_CONFIG_NAME);
		columns.push(me.createActionColumn());
		const actionsToolbar = me.createActionsToolbar();

		return {
			columns: columns,
			region: 'center',
			dockedItems: [actionsToolbar],
			selModel: createGridCheckboxSelectionModel({
				topBar: actionsToolbar,
				selectionProcessor: me.selectionProcessor.bind(me)
			}),
			listeners: {
				celldblclick: function (
					_view: ExtComponent,
					_td: unknown,
					_cellIndex: number,
					record: ExtRecord<DocumentHeader>
				) {
					let recordData = record.getData();
					edi.document.actions.openDetailsModule(recordData.type, recordData);
				}
			}
		};
	}

	createGrid(): DocumentsForSign['grid'] {
		const me = this;
		return createGrid({
			saveSorters: true,
			savedSortersName: me.moduleData.modName,
			proxyConfig: me.getProxyConfig(),
			storeConfig: me.getStoreConfig(),
			gridConfig: me.getGridConfig(),
			pagingBarConfig: me.getPagingBarConfig()
		}) as ExtComponent;
	}

	async fetchData() {
		const me = this;
		const { success, data } = await edi.rest.asyncSendRequest({
			url: documentsForSignApi.GET_MAX_SIGN_COUNT,
			method: 'GET'
		});
		if (success) {
			me.maxSignLimit = +data.data;
		}
	}

	createModuleContent() {
		const me = this;
		me.modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		}) as ExtComponent;
		me.grid = me.createGrid();
		me.filterForm = me.createFilterForm();
		me.filterObject = me.createFilterObject();
		me.modulePanel.add(me.filterForm);
		me.modulePanel.add(me.grid);
		me.moduleData.tab.add(me.modulePanel);
	}

	renderData(initCallBack?: Function) {
		const me = this;
		me.fetchData().then(() => {
			me.createModuleContent();
			initCallBack?.();
			me.fireSearch();
		});
	}

	onDestroy() {
		const me = this;
		if (me.filterObject?.searchTimeout) {
			clearTimeout(me.filterObject.searchTimeout);
		}
		edi.events.documents.un('create', me._refreshHandler);
		edi.events.documents.un('change', me._refreshHandler);
		return true;
	}
}

Ext.namespace('edi.modulesCfg');
edi.modulesCfg['documentsForSign'] = {
	title: 'module.tab.documents_for_sign',
	modName: 'documentsForSign',
	permissions: ['SIGN_EXTENDED']
};

Ext.namespace('edi.modules');
edi.modules['documentsForSign'] = DocumentsForSign;
