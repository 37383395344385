import { initToastManager } from './components/toastManager';
import { DOCS_WITH_BUSINESS_STATE } from '../edi/configuration';
import { POA_CONST } from '@Edi/modules/power_of_attorney/configuration';
import { ediHelpChat } from '@Ediweb/commons/ediHelpChat';

edi.constants.IS_EDIWEB_CLIENT = true;
edi.constants.NAVIGATION_NAME = 'ediweb_client';
edi.constants.LOCALIZATION_PACKAGE_NAME = 'Ediweb';

edi.constants.DEFAULT.BACKGROUND.ON_START = true;
edi.constants.DEFAULT.BACKGROUND.ROTATION = 'LIST';
edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX = 'edi-viewport-background-';
edi.constants.DEFAULT.BACKGROUND.IMG_LIST = ['ediweb'];
edi.constants.DEFAULT.SHOW_LOGIN_HEADER = true;
edi.constants.MODULES_CLOSE_FIRST = false;
edi.constants.SHOW_TOOLTIPS = false;
edi.constants.ENABLE_CERTIFICATE_APPLICATION_CHECKING = true;

edi.constants.COMBOBOXFIELD_CLICK_ENABLED = true;
edi.constants.DATEFIELD_CLICK_ENABLED = true;
edi.constants.TIMEFIELD_CLICK_ENABLED = true;
edi.constants.DATE_FORMAT.MILLISECONDS = 'time';

edi.constants.DEFAULT.LOGIN_FORM_HIDE_MASK = true;
edi.constants.DEFAULT.LOGIN_FORM_HIDE_MASK = true;
Ext.merge(edi.constants.DEFAULT, {
	DOCUMENT_HEADER: {
		itemCols: 3,
		colsInRow: 5
	}
});

edi.constants.DISABLE_GRID_EXPORT = false;

edi.constants.INIT_LOCALIZATION_GROUPS.push('ediweb_init');

edi.constants.ENABLE_POA_FOR_ALL_CERT = false;

Object.assign(edi.constants.DEFAULT.HEADER_CONFIG, {
	height: 72
});

Object.assign(edi.constants.DEFAULT.COMPONENTS_SETTINGS, {
	SIDEBAR: {
		WIDTH: 425
	}
});

Ext.merge(edi.constants.DEFAULT.TABPANEL_HEADER_CONFIG, {
	margin: '20 20 0 20',
	listeners: {
		render() {
			//делаем это тут, что б инициализировать тосты после создания основного лэйаута
			Ext.namespace('edi.toast');
			edi.toast = initToastManager({
				cls: 'edi-ediweb-notification-toasts-container',
				parentContainer: edi.core.getViewPort()?.el?.dom
			});
		}
	}
});

edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO_MODULE_CLICK = 'main';

edi.constants.DEFAULT.COMPONENTS_SETTINGS.TAB_PANEL_CLASS = 'edi-tab-bar edi-ediweb-tab-bar';

// этот класс нужен чтобы для этих табов не применялись стили табов из модулей
edi.constants.DEFAULT.COMPONENTS_SETTINGS.USER_ORG_COMBO = {
	width: 200,
	cls: 'edi-main-header-panel-org'
};

edi.constants.DEFAULT.MODAL.COMPANY_SELECTOR_WIDTH = 720;
edi.constants.DEFAULT.MODAL.COMPANY_SELECTOR_HEIGHT = 608;
edi.constants.DEFAULT.MODAL.COMPANY_SELECTOR_TOOLBAR_HEIGHT = 60;

edi.constants.DEFAULT.DISPLAY_NOTIFICATIONS_TIME = '20';
edi.constants.DEFAULT.MAX_NOTIFICATIONS_COUNT = '5';

edi.constants.CERTIFICATE.ALLOW_ACTIVE = false;

Object.assign(edi.constants.ICONS, {
	APPS: 'xe5c3@Material Icons',
	CHEVRON_DOUBLE_LEFT: 'xe5cb@Material Icons',
	CHEVRON_DOUBLE_RIGHT: 'xe5cc@Material Icons',
	FINNEL: 'xe502@EdiSoftIcons',
	FILE: 'xe24d@Material Icons',
	COIN: 'xe263@Material Icons',
	PEP_SIGN: 'xe900b@EdiSoftIcons',
	HORIZONTAL_DOTS: 'xe5d3@Material Icons',
	ENVELOPE: 'xe151@Material Icons',
	HUMAN: 'xe7fd@Material Icons',
	CLIP: 'xe226@Material Icons',
	REVOKE: 'xe923@Material Icons',
	LINK: 'xe157@Material Icons',
	LOCK: 'xe644@Material Icons',
	UNLOCK: 'xe643@Material Icons',
	ATTACHMENT_UPLOAD: 'xe2c6@Material Icons',
	ARROW_UPWARD: 'xe5d8@Material Icons',
	FOLDER_ATTACHMENT: 'xe24d@Material Icons',
	USER_PROFILE: 'xe907@Material Icons',
	SUCCESS: 'xe876@Material Icons',
	NO: 'xe033@Material Icons',
	APPROVAL_DOCUMENTS: 'xe903@EdiwebIcons',
	CENTER_FOCUS_STRONG: 'xe3b4@Material Icons',
	NOTE_ADD: 'xe89c@Material Icons',
	HELP_OUTLINE: 'xe8fd@Material Icons',
	ADD_EMPLOYEE: 'xe7fe@Material Icons'
});

Object.assign(edi.constants.ORGANIZATION_TYPES, {
	PERSON: 'PERSON'
});

Object.assign(edi.constants.DOCUMENT_TYPES, {
	ONBOARDING_CONTAINER: 'ONBOARDING_CONTAINER',
	INVITATION: 'INVITATION',
	MASS_INVITATION: 'MASS_INVITATION',

	DSF_NDA: 'DSF_NDA',
	DSF_SUPAGREEMENT_ANNUL: 'DSF_SUPAGREEMENT_ANNUL',
	DSF_AKTPRM: 'DSF_AKTPRM',
	DSF_ONBOARDING_DC1: 'DSF_ONBOARDING_DC1',
	DSF_ONBOARDING_DC2: 'DSF_ONBOARDING_DC2',
	DSF_ONBOARDING_DC3: 'DSF_ONBOARDING_DC3',
	DSF_ONBOARDING_DC4: 'DSF_ONBOARDING_DC4',
	DSF_ONBOARDING_DC5: 'DSF_ONBOARDING_DC5',
	DSF_ONBOARDING_DC6: 'DSF_ONBOARDING_DC6',
	DSF_ONBOARDING_CP: 'DSF_ONBOARDING_CP',
	DSF_ONBOARDING_FP: 'DSF_ONBOARDING_FP',

	EDI_APPLICATION_TO_FNS: 'EDI_APPLICATION_TO_FNS',
	EDI_APPLICATION_TO_FNS_CHANGE: 'EDI_APPLICATION_TO_FNS_CHANGE',

	REGISTRATION_ACCEPTANCE: 'REGISTRATION_ACCEPTANCE'
});

edi.constants.INVITATION_TYPE = {
	REQUEST: 'ЗАПРОС',
	GAP: 'РАЗРЫВ'
};

Object.assign(edi.constants.VALIDATORS_MULTI.RU, {
	SNILS: /^\d{3}-\d{3}-\d{3}\s\d{2}$/i
});

edi.constants.FIELD_MASK = {
	SNILS: '000-000-000 00',
	PHONE: '+7 000 000-00-00',
	PASSPORT_NUMBER: '00 00 000000',
	OGRN: '0000000000000',
	INN: '000000000000'
};

edi.constants.DOCUMENT_SUBTYPES = {
	USTDOKVEGRUL: 'USTDOKVEGRUL',
	USTDOKUCHR: 'USTDOKUCHR',
	USTDOKDOP: 'USTDOKDOP',
	USTDOKDOV: 'USTDOKDOV',
	USTDOKIEIO: 'USTDOKIEIO'
};
Ext.merge(edi.constants.STATE, {
	CORRECTION: 'CORRECTION',
	AMEND: 'AMEND'
});

Object.assign(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD, {
	SCHF_DOP_DECREE_14_WITHOUT_OPERATOR: 'FNS_UPD_SCHF_DOP_DECREE_14_WITHOUT_OPERATOR',
	SCHF_DECREE_14_WITHOUT_OPERATOR: 'FNS_UPD_SCHF_DECREE_14_WITHOUT_OPERATOR',
	DOP_DECREE_14_WITHOUT_OPERATOR: 'FNS_UPD_DOP_DECREE_14_WITHOUT_OPERATOR'
});
Object.assign(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD, {
	KSCHF_DIS_DECREE_14_WITHOUT_OPERATOR: 'FNS_UKD_KSCHF_DIS_DECREE_14_WITHOUT_OPERATOR',
	KSCHF_DECREE_14_WITHOUT_OPERATOR: 'FNS_UKD_KSCHF_DECREE_14_WITHOUT_OPERATOR',
	DIS_DECREE_14_WITHOUT_OPERATOR: 'FNS_UKD_DIS_DECREE_14_WITHOUT_OPERATOR'
});
edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE = {
	SCHFDOP_SCHFDOP: edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
	SCHFDOP_SCHF: edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
	SCHFDOP_DOP: edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	SCHFDOP_KSCHFDIS: edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS,
	SCHFDOP_KSCHF: edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
	SCHFDOP_DIS: edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS
};
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP] =
	edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_SCHFDOP;
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF] =
	edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_SCHF;
// edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14] =
// 	edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_SCHFDOP;
// edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14] =
// 	edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_SCHF;
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP] =
	edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_DOP;

edi.constants.FNS_BPNAMES_CREATE_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14_WITHOUT_OPERATOR
] = edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_KSCHFDIS;
edi.constants.FNS_BPNAMES_CREATE_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14_WITHOUT_OPERATOR
] = edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_KSCHF;
edi.constants.FNS_BPNAMES_CREATE_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_DECREE_14_WITHOUT_OPERATOR
] = edi.constants.DOCUMENT_BP_NAMES_FOR_CREATE.SCHFDOP_DIS;

edi.constants.UPD_BP_NAMES_FOR_FILTER = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP
];

edi.constants.UKD_BP_NAMES_FOR_FILTER = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS
];

edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS;
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF;
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS;

Object.assign(edi.constants.DOCUMENT_BP_NAMES.DSF_ALL, {
	V2: 'DSF_V2'
});

edi.constants.WEB_UPLOAD_DOCTYPES = [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1];

edi.constants.FNS_BPNAMES_PERMISSION_PREFIX['SCHF_WITHOUT_OPERATOR'] = 'SCHF_WITHOUT_OPERATOR';
edi.constants.FNS_BPNAMES_PERMISSION_PREFIX['SCHFDOP_WITHOUT_OPERATOR'] = 'SCHFDOP_WITHOUT_OPERATOR';
edi.constants.FNS_BPNAMES_PERMISSION_PREFIX['KSCHF_WITHOUT_OPERATOR'] = 'KSCHF_WITHOUT_OPERATOR';
edi.constants.FNS_BPNAMES_PERMISSION_PREFIX['KSCHF_DIS_WITHOUT_OPERATOR'] = 'KSCHF_DIS_WITHOUT_OPERATOR';

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DOP_DECREE_14_WITHOUT_OPERATOR
] = edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP_WITHOUT_OPERATOR;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DECREE_14_WITHOUT_OPERATOR] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF_WITHOUT_OPERATOR;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS_WITHOUT_OPERATOR;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_WITHOUT_OPERATOR;

// edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14] =
// 	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP_WITHOUT_OPERATOR;
// edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14] =
// 	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF_WITHOUT_OPERATOR;
// edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14] =
// 	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS_WITHOUT_OPERATOR;
// edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14] =
// 	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_WITHOUT_OPERATOR;

Ext.merge(edi.constants.DOCUMENT_ACTIONS, {
	EXPORT_GROUP_COMMON: 'EXPORT_GROUP_COMMON',
	EXPORT_ATTACHMENT: 'EXPORT_ATTACHMENT',
	SEND_APPROVE: 'SEND_APPROVE',
	CORRECT: 'CORRECT',
	SEND_TO_APPROVE_DOCUMENTS: 'SEND_TO_APPROVE_DOCUMENTS',
	SEND_TO_APPROVE_EMPLOYEES: 'SEND_TO_APPROVE_EMPLOYEES',
	GROUP_SEND: 'GROUP_SEND',
	REASSIGN: 'REASSIGN',
	GO_TO_SIGN: 'GO_TO_SIGN'
});

edi.constants.ACTUAL_FREE_DOCUMENT_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
];

edi.constants.CREATE_ACTUAL_FREE_DOCUMENT_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
];

edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES = edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,

	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
]);
edi.constants.EW_SIGN_DOCUMENT_TYPES = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,

	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,

	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP
];

// EW-662
edi.constants.EW_DOCUMENT_TYPES_NO_SOME_ACTIONS = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,

	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,

	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP
];
edi.constants.FREE_DOCUMENT_TYPES = edi.constants.FREE_DOCUMENT_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,

	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
]);

edi.constants.DOCS_WITH_ANNUL_LOG.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
);

edi.constants.REJECT_DSFV3 = edi.constants.REJECT_DSFV3.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,

	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
]);

edi.constants.DSF_TYPES_TO_SIGN_IZVPOL_WHILE_REJECTING = [
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
];

edi.constants.documentsForVisibleTemplates = edi.constants.documentsForVisibleTemplates.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
]);

edi.constants.EMAIL_NOTIFICATION_DOC_TYPES = edi.constants.EMAIL_NOTIFICATION_DOC_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
]);
edi.constants.MODULES.DOCUMENTS.COPY_USING_UI = edi.constants.MODULES.DOCUMENTS.COPY_USING_UI.concat([
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
]);
edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES = edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
]);
edi.constants.MODULES.DOCUMENTS.CUSTOM_FIELDS_INCLUDED_TYPES =
	edi.constants.MODULES.DOCUMENTS.CUSTOM_FIELDS_INCLUDED_TYPES.concat([
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
	]);
edi.constants.MODULES.APERAK_DOCUMENT_TYPES = edi.constants.MODULES.APERAK_DOCUMENT_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
]);
edi.constants.SIGNATURE_SIDE.INCOMING.CONSUMER = edi.constants.SIGNATURE_SIDE.INCOMING.CONSUMER.concat([
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
]);
edi.constants.SIGNATURE_SIDE.OUTGOING.SUPPLIER = edi.constants.SIGNATURE_SIDE.OUTGOING.SUPPLIER.concat([
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
]);

edi.constants.USE_BEFORE_SIGN_RULES = edi.constants.USE_BEFORE_SIGN_RULES.push([
	{
		DOCUMENT_TYPES: [
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
			edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
			edi.constants.DOCUMENT_TYPES.DSF_NDA,
			edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
			edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
		],
		STATES: [edi.constants.STATE.SENT, edi.constants.STATE.WAIT_RECEIVER_DECISION]
	}
]);

edi.constants.MODULES.DOCUMENTS.INCLUDED_STATES = [
	edi.constants.STATE.DRAFT,
	edi.constants.STATE.SENDER_REVIEW,
	edi.constants.STATE.RECEIVER_REVIEW,
	edi.constants.STATE.REJECTED,
	edi.constants.STATE.SIGNED_BY_CONSUMER,
	edi.constants.STATE.DELETED,
	edi.constants.STATE.SENT,
	edi.constants.STATE.READ,
	edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL,
	edi.constants.STATE.COMPLETED,
	edi.constants.STATE.UTOCH,
	edi.constants.STATE.AMEND,
	edi.constants.STATE.CORRECTION,
	edi.constants.STATE.CANCELED,
	edi.constants.STATE.ON_ANNUL,
	edi.constants.STATE.RENOUNCEMENT
];

edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_NDA] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_AKTPRM] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP] = 'document.create.dsf';

edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_NDA] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_AKTPRM] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6] = 'document.details.dsf';

edi.constants.INVITATIONS = {
	STATE: {
		DRAFT: 'DRAFT',
		SENT: 'SENT',
		DELETED: 'DELETED',
		EXPECTATION: 'EXPECTATION',
		REJECTED: 'REJECTED',
		REVOKE: 'REVOKE',
		EXPIRED: 'EXPIRED',
		COMPLETED: 'COMPLETED'
	}
};

edi.constants.NOTIFICATION_TYPE = {
	NEW_DOCUMENT: 'NEW_DOCUMENT',
	NEW_STATUS_OF_DOCUMENT: 'NEW_STATUS_OF_DOCUMENT',
	NEW_INVITATION: 'NEW_INVITATION',
	NEW_STATUS_OF_INVITATION: 'NEW_STATUS_OF_INVITATION',
	NEW_CONNECTED_PRODUCT: 'NEW_CONNECTED_PRODUCT',
	INFORMATION: 'INFORMATION',
	PARTNERSHIP: 'PARTNERSHIP',
	DSF_ONBOARDING: 'DSF_ONBOARDING',
	APPROVAL_DOCUMENTS: {
		ON_APPROVAL: 'ON_APPROVAL',
		REJECTED: 'REJECTED',
		REVOKED: 'REVOKED',
		APPROVE_NEXT_STAGE: 'APPROVE_NEXT_STAGE',
		SIGN_NEXT_STAGE: 'SIGN_NEXT_STAGE',
		APPROVED: 'APPROVED'
	},
	APPROVAL_EMPLOYEES: {
		REASSIGN_NEXT_STAGE: 'REASSIGN_NEXT_STAGE',
		EMPLOYEE_ON_APPROVAL: 'EMPLOYEE_ON_APPROVAL',
		EMPLOYEE_APPROVE_NEXT_STAGE: 'EMPLOYEE_APPROVE_NEXT_STAGE',
		EMPLOYEE_SIGN_NEXT_STAGE: 'EMPLOYEE_SIGN_NEXT_STAGE',
		EMPLOYEE_APPROVED: 'EMPLOYEE_APPROVED',
		EMPLOYEE_SIGNED: 'EMPLOYEE_SIGNED',
		EMPLOYEE_COMPLETED: 'EMPLOYEE_COMPLETED',
		EMPLOYEE_REJECTED: 'EMPLOYEE_REJECTED',
		EMPLOYEE_REVOKED: 'EMPLOYEE_REVOKED'
	},
	TARIFFS: 'TARIFFS',
	REGISTRATION_ACCEPTANCE: 'REGISTRATION_ACCEPTANCE',
	POA_FOR_SIGNING: 'POA_FOR_SIGNING',
	POWER_OF_ATTORNEY: 'POWER_OF_ATTORNEY'
};

edi.constants.VALIDATORS.PHONE =
	/^(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})$/im;
edi.constants.VALIDATORS.INN = /^([0-9]{1}[0-9]{1}|[1-9]{1}[0-9]{1})([0-9]{8}|[0-9]{10})$/im;

edi.constants.ORG_PROFILE = {
	EMPLOYEES: {
		STATE: {
			NOT_CONFIRMED: 'NOT_CONFIRMED',
			CONFIRMED: 'CONFIRMED',
			DEACTIVATED: 'DEACTIVATED',
			DELETED: 'DELETED'
		},
		ACTION: {
			POWER_OF_ATTORNEY: 'POWER_OF_ATTORNEY',
			NEW_DOCUMENTS_CREATE_EDIT_SEND: 'NEW_DOCUMENTS_CREATE_EDIT_SEND',
			DOCUMENTS_SIGN_REJECT_ANNUL_CLARIFY: 'DOCUMENTS_SIGN_REJECT_ANNUL_CLARIFY',
			EMPLOYEES_SUBDIVISIONS: 'EMPLOYEES_SUBDIVISIONS',
			NEW_DOCUMENTS_READ: 'NEW_DOCUMENTS_READ',
			NEW_DOCUMENTS_DELETE: 'NEW_DOCUMENTS_DELETE',
			USER_ADMINISTRATION: 'USER_ADMINISTRATION',
			ROUTES: 'ROUTES'
		}
	}
};

edi.constants.ORG_PROFILE.EMPLOYEES.DELETE_STATE = [
	edi.constants.ORG_PROFILE.EMPLOYEES.STATE.CONFIRMED,
	edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DEACTIVATED,
	edi.constants.ORG_PROFILE.EMPLOYEES.STATE.NOT_CONFIRMED
];

edi.constants.ORG_PROFILE.EMPLOYEES.actionsTree = {
	POA: {
		order: 90,
		items: [edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.POWER_OF_ATTORNEY]
	},
	NEW_DOCS: {
		order: 100,
		items: [
			edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.NEW_DOCUMENTS_READ,
			edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.NEW_DOCUMENTS_CREATE_EDIT_SEND,
			edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.NEW_DOCUMENTS_DELETE
		]
	},
	WORK_WITH_DOCS: {
		order: 200,
		items: [edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.DOCUMENTS_SIGN_REJECT_ANNUL_CLARIFY]
	},
	WORK_WITH_SYSTEM: {
		order: 300,
		items: [
			edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.EMPLOYEES_SUBDIVISIONS,
			edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.ROUTES
		]
	},
	ADMINISTRATION: {
		order: 400,
		items: [edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.USER_ADMINISTRATION]
	}
};

edi.constants.ORG_PROFILE.ROUTES = {
	ROUTE_TYPE: {
		PRIVATE: 'PRIVATE',
		GLOBAL: 'GLOBAL'
	},
	ORDER_OF_AGREEMENT: {
		PARALLEL: 'PARALLEL',
		SEQUENTIAL: 'SEQUENTIAL',
		COMPOSITE: 'COMPOSITE'
	}
};

edi.constants.FIRST_HALF_BUTTONS_ACTIONS = edi.constants.FIRST_HALF_BUTTONS_ACTIONS.concat([
	edi.constants.DOCUMENT_ACTIONS.APPROVE,
	edi.constants.DOCUMENT_ACTIONS.SEND_APPROVE,
	edi.constants.DOCUMENT_ACTIONS.CORRECT,
	edi.constants.DOCUMENT_ACTIONS.GROUP_SEND,
	edi.constants.DOCUMENT_ACTIONS.REASSIGN,
	edi.constants.DOCUMENT_ACTIONS.GO_TO_SIGN
]);

edi.constants.APPROVAL_DOCUMENTS = {
	TYPE: 'APPROVAL_DOCUMENTS',
	STATE: {
		DRAFT: 'DRAFT',
		ON_APPROVAL: 'ON_APPROVAL',
		ON_APPROVAL_BY_RECEIVER: 'ON_APPROVAL_BY_RECEIVER',
		REJECTED: 'REJECTED',
		APPROVED: 'APPROVED',
		REVOKED: 'REVOKED',
		DELETED: 'DELETED'
	},
	SIDEBAR_STATE: {
		SIGN_AWAITING: 'SIGN_AWAITING',
		SIGN: 'SIGN',
		APPROVAL_AWAITING: 'APPROVAL_AWAITING',
		APPROVAL: 'APPROVAL',
		REJECTED: 'REJECTED'
	},
	TYPES: {
		APPROVAL_DOCUMENTS: 'APPROVAL_DOCUMENTS',
		APPROVAL_EMPLOYEES: 'APPROVAL_EMPLOYEES'
	}
};

edi.constants.DSF_WITH_APPROVE = [
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT
];

edi.constants.UPD_WITH_APPROVE = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DOP_DECREE_14_WITHOUT_OPERATOR,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DECREE_14_WITHOUT_OPERATOR,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_DECREE_14_WITHOUT_OPERATOR
];

edi.constants.UKD_WITH_APPROVE = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_DECREE_14_WITHOUT_OPERATOR,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DECREE_14_WITHOUT_OPERATOR,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.DIS_DECREE_14_WITHOUT_OPERATOR
];

// проверяется по наличию действующего тарифа
edi.constants.IS_CAN_CREATE_DOCUMENT = false;
edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = false;
edi.constants.IS_CAN_CREATE_POA = false;
edi.constants.IS_EXPIRED_POA_TARIFF = false;

edi.constants.INTRO_MODAL_IS_VISIBLE = false;
edi.constants.BEFORE_LOAD_MODULES = edi.constants.BEFORE_LOAD_MODULES.concat([
	(cb) => edi.methods.beforeLoadModules.showAcceptanceWindow(cb),
	(cb) => edi.methods.beforeLoadModules.showAgreementPersonalData(cb),
	(cb) => edi.methods.beforeLoadModules.introModal(cb),
	(cb) => edi.methods.beforeLoadModules.initRealTimeMessage(cb),
	(cb) => {
		edi.events.partners.on('change', function () {
			edi.core._getRelations();
		});
		cb();
	},
	(cb) => {
		setInterval(function () {
			edi.core._getRelations();
		}, 100000);

		cb();
	},
	(cb) => ediHelpChat.initChat(cb)
]);

edi.constants.CERTIFICATES = {
	STATES: [
		edi.constants.STATE.DRAFT,
		edi.constants.STATE.ACTIVE,
		edi.constants.STATE.DEACTIVATED,
		edi.constants.STATE.REVIEW,
		edi.constants.STATE.REJECTED
	]
};

DOCS_WITH_BUSINESS_STATE.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
);

edi.constants.SIGN_TYPES = {
	SIGN_PEP: 'SIGN_PEP',
	SIGN_UKEP: 'SIGN_UKEP',
	SIGN_PEP_UKEP: 'SIGN_PEP_UKEP',
	WITHOUT_SIGN: 'WITHOUT_SIGN'
};

POA_CONST.ALLOW_ACTIONS_WITH_POA = true;

edi.constants.DSF_ONBOARDING_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
];

edi.constants.APPROVAL_DOCUMENTS_TYPES = [
	...edi.constants.DSF_WITH_APPROVE,
	...edi.constants.UPD_WITH_APPROVE,
	...edi.constants.UKD_WITH_APPROVE
];
