import { createAutocomplete } from '@Core/specialComponents/fields';
import { createProxyConfig } from '@Components/storeComponents';
import { createFieldContainer } from '@Components/fields';
import { createComboLogo } from '@Ediweb/components/ComboLogo/ComboLogo';
import { createOrgAutocomplete } from '@Ediweb/components/OrgAutocomplete/OrgAutocomplete';
import { createFormContainer } from '@UIkit/components/panels';
import { createCombo, createTextField, createCheckbox } from '@UIkit/components/fields';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';

const notEmpty = function (str) {
	return !!str && str !== 'null' && str !== 'undefined';
};

Ext.namespace('edi.filters');
Object.assign(edi.filters, {
	getProduct: function () {
		var store = edi.utils.deepCloneStore(edi.stores.initDefaultProductList());
		if (!store.getById(0)) {
			store.insert(0, {
				label: edi.i18n.getMessage('form.combo.not.selected'),
				name: ''
			});
		}

		return createComboLogo({
			fieldLabel: edi.i18n.getMessage('invitations.column.product'),
			name: 'productRole',
			store: store,
			anyMatch: true,
			displayField: 'label',
			valueField: 'name'
		});
	}
});

Object.assign(edi.filters.config, {
	documents: {
		createFormItems: function () {
			const enableUnlimitedDatesSearch = edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD');

			let formItemsMap = {},
				docStates = [],
				includedStates = edi.constants.MODULES.DOCUMENTS.INCLUDED_STATES;

			var setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
				if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
					dateRangeFields.dateFrom.allowBlank = allowBlank;
					dateRangeFields.dateTo.allowBlank = allowBlank;
				}
			};
			var dateFieldsValidator = function () {
				var docDateFields,
					docDatesSelected,
					modifyDateFields,
					modifyDatesSelected,
					creationDateFields,
					creationDatesSelected,
					docDatesIsValid,
					modifyDatesIsValid,
					creationDatesIsValid;

				if (
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE] &&
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE] &&
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]
				) {
					docDateFields = formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE].getFields();
					modifyDateFields = formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE].getFields();
					creationDateFields = formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE].getFields();
					docDatesSelected = docDateFields.dateTo.getValue() || docDateFields.dateFrom.getValue();
					modifyDatesSelected = modifyDateFields.dateTo.getValue() || modifyDateFields.dateFrom.getValue();
					creationDatesSelected =
						creationDateFields.dateTo.getValue() || creationDateFields.dateFrom.getValue();

					docDatesIsValid = docDatesSelected
						? !!docDateFields.dateTo.getValue() && !!docDateFields.dateFrom.getValue()
						: true;
					modifyDatesIsValid = modifyDatesSelected
						? !!modifyDateFields.dateTo.getValue() && !!modifyDateFields.dateFrom.getValue()
						: true;
					creationDatesIsValid = creationDatesSelected
						? !!creationDateFields.dateTo.getValue() && !!creationDateFields.dateFrom.getValue()
						: true;

					if (
						(docDatesSelected && modifyDatesSelected && creationDatesSelected) ||
						(!docDatesSelected && !modifyDatesSelected && !creationDatesSelected) ||
						(!docDatesIsValid && !modifyDatesIsValid && !creationDatesIsValid)
					) {
						setAllowBlankForDateRange(docDateFields, false);
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(modifyDateFields, false);
					} else if (modifyDatesSelected) {
						//If modifyDate selected
						setAllowBlankForDateRange(modifyDateFields, false);
						setAllowBlankForDateRange(docDateFields, docDatesIsValid);
						setAllowBlankForDateRange(creationDateFields, creationDatesIsValid);
					} else if (docDatesSelected) {
						setAllowBlankForDateRange(docDateFields, false);
						setAllowBlankForDateRange(creationDateFields, creationDatesIsValid);
						setAllowBlankForDateRange(modifyDateFields, modifyDatesIsValid);
					} else if (creationDatesSelected) {
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(docDateFields, docDatesIsValid);
						setAllowBlankForDateRange(modifyDateFields, modifyDatesIsValid);
					}
				}
				return true;
			};

			Object.values(edi.constants.STATE).forEach(function (value) {
				if (
					includedStates.some((it) => it === value) &&
					!edi.constants.MODULES.DOCUMENTS.FILTER_REMOVED_STATES.some((it) => it === value)
				) {
					var searchMap = ['BUSINESS_STATE', value];
					if (
						value === edi.constants.STATE.SENT ||
						value === edi.constants.STATE.WAIT_RECEIVER_DECISION ||
						value === edi.constants.STATE.READ ||
						value === edi.constants.STATE.SIGNED_BY_CONSUMER
					) {
						searchMap.push('DOCUMENTS_FILTER');
					}

					docStates.push({
						id: value,
						name: edi.i18n.getTr(searchMap)
					});
				}
			});

			docStates.push({
				id: edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL,
				name: edi.i18n.getMessage('documents.status.ON_APPROVAL.documents.filter')
			});

			formItemsMap = {
				includeBusinessStates: createCombo({
					fieldLabel: edi.i18n.getMessage('column.status'),
					store: edi.stores.createInlineStore(docStates, 'SIMPLE', undefined, {
						addEmptyRecord: true
					}),
					multiSelect: true,
					setLastValueAfterTextClear: true,
					anyMatch: true,
					name: 'includeBusinessStates'
				}),
				docType: createCombo({
					fieldLabel: edi.i18n.getMessage('document.type'),
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
							edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
							edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
							edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
							edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
							edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
							edi.constants.DOCUMENT_TYPES.DSF_NDA,
							edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
							edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
							edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP
						],
						function (type) {
							return edi.core.getMessage('documents.doctype.' + type);
						},
						true
					),
					multiSelect: true,
					anyMatch: true,
					name: 'includeDocTypes'
				}),
				numberDocument: createTextField({
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					name: 'number'
				}),
				partner: createCombo({
					fieldLabel: edi.i18n.getMessage('documents.form.partner'),
					emptyText: '',
					store: edi.stores.initRelatedOrganizationsFirstEmptyStore(),
					anyMatch: true,
					autoValue: true,
					name: 'partner',
					showQtips: true,
					listeners: {
						change: function (combo, newValue) {
							let selectedOrg = combo.getStore().getById(newValue)?.data;
							let filiationsCombo = formItemsMap.includedFilials;
							let filiationsStore = filiationsCombo?.getStore();

							filiationsCombo?.setVisible(selectedOrg?.hasFilials);
							if (filiationsStore) {
								if (selectedOrg?.hasFilials) {
									filiationsStore.setProxy(
										createProxyConfig({
											type: 'ajax',
											url: edi.utils.compileURL(edi.rest.services.FILIATIONS.GET, {
												headOrgId: selectedOrg.id
											})
										})
									);
									filiationsStore.load();
								} else {
									filiationsCombo.setValue(null);
								}
							}
						}
					}
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
					period: 'week',
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					validator: dateFieldsValidator,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							fieldLabel: edi.i18n.getMessage('document.modify.date.from'),
							chipTitle: edi.i18n.getMessage('document.date.modify') + ': '
						},
						to: {
							chipTitle: ' — '
						}
					}
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('documents.column.date'),
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					validator: dateFieldsValidator,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							fieldLabel: edi.i18n.getMessage('document.date.from'),
							chipTitle: edi.i18n.getMessage('documents.column.date') + ': ',
							name: 'fromDocTime'
						},
						to: {
							chipTitle: ' — ',
							name: 'toDocTime'
						}
					}
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					validator: dateFieldsValidator,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							fieldLabel: edi.i18n.getMessage('document.created.from'),
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				})
			};
			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [[4, 4, 4], 4, [4, 4, 4]],
					items: [
						formItemsMap.includeBusinessStates,
						formItemsMap.docType,
						formItemsMap.numberDocument,
						formItemsMap.partner,
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE],
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]
					]
				})
			];
			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values.ownDocs) {
				values.ownDocs = 'true';
			}
			if (values['includeBusinessStates']) {
				const businessStates = [];
				values['includeBusinessStates'].forEach(function (value) {
					businessStates.push({ businessState: value });
					switch (value) {
						//если в фильтре выбрано SIGNED_BY_CONSUMER добавить еще SIGNED
						case edi.constants.STATE.SIGNED_BY_CONSUMER:
							businessStates.push({ businessState: edi.constants.STATE.SIGNED });
							break;
						//если REJECTED добавить еще REJECTED_BY_RECEIVER
						case edi.constants.STATE.REJECTED:
							businessStates.push({ businessState: edi.constants.STATE.REJECTED_BY_RECEIVER });
							break;
						//если "Принят с комментарием" добавить еще ACCEPTED_WITH_COMMENT_34
						case edi.constants.STATE.ACCEPTED_WITH_COMMENT_13:
							businessStates.push({ businessState: edi.constants.STATE.ACCEPTED_WITH_COMMENT_34 });
							break;
						//если ON_APPROVAL добавить еще ON_APPROVAL_BY_RECEIVER
						case edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL:
							businessStates.push({
								businessState: edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL_BY_RECEIVER
							});
							break;
						//если в фильтре выбрано UTOCH добавить еще UTOCH_COMPLETED и UTOCH_SIGNED_BY_CONSUMER
						case edi.constants.STATE.UTOCH:
							businessStates.push(
								{
									businessState: edi.constants.STATE.UTOCH_COMPLETED
								},
								{
									businessState: edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER
								}
							);
							break;
					}
				});
				delete values['includeBusinessStates'];

				if (businessStates.length) {
					values['businessStates'] = Ext.encode(businessStates);
				}
			}

			if (values['includeDocTypes']) {
				//Check if UPD or UKD business process was selected. If so, send selected doctypes & BP-s as separate filter arg
				var bpNamesUPD = Array.isArray(edi.constants.UPD_BP_NAMES_FOR_FILTER)
					? edi.constants.UPD_BP_NAMES_FOR_FILTER
					: [];
				var bpNamesUKD = Array.isArray(edi.constants.UKD_BP_NAMES_FOR_FILTER)
					? edi.constants.UKD_BP_NAMES_FOR_FILTER
					: [];
				var bpNamesPricat = Array.isArray(edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER)
					? edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER
					: [];

				var selectedBpNames = [];

				if (!['priceCatalogue', 'archived', 'aperak'].some((it) => it === values.gridName)) {
					var bpNamesFNS = [],
						simpleDocTypes = [];

					bpNamesFNS = bpNamesFNS.concat(bpNamesUPD);
					bpNamesFNS = bpNamesFNS.concat(bpNamesUKD);
					bpNamesFNS.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT);

					var isAllUPDTypes =
						values['includeDocTypes'].filter(function (elem) {
							return bpNamesUPD.indexOf(elem) > -1;
						}).length === bpNamesUPD.length;
					var isAllUKDTypes =
						values['includeDocTypes'].filter(function (elem) {
							return bpNamesUKD.indexOf(elem) > -1;
						}).length === bpNamesUKD.length;
					var isAllFNSType = bpNamesFNS.every(function (elem) {
						return values['includeDocTypes'].indexOf(elem) > -1;
					});
					var isAllPricatTypes = bpNamesPricat.every(function (elem) {
						return values['includeDocTypes'].indexOf(elem) > -1;
					});

					if (
						isAllFNSType ||
						values['includeDocTypes'].some(
							(it) => it === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
						)
					) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
						});
					}
					if (isAllUPDTypes) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
						});
					}
					if (isAllUKDTypes) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
						});
					}
					if (isAllPricatTypes) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
						});
					}

					//Check for UPD and UKD BP-s in doctypes if no general UPD & UKD values are selected
					values['includeDocTypes'].forEach(function (doctype) {
						var isSimpleDocTypes =
							!bpNamesUPD.some((it) => it === doctype) &&
							!bpNamesUKD.some((it) => it === doctype) &&
							!bpNamesPricat.some((it) => it === doctype) &&
							doctype !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT;

						if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
								name: 'subDocType',
								value: edi.stores.data.formalizedDocumentSubTypes[doctype]
							});
						} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
								name: 'subDocType',
								value: edi.stores.data.dsfClientDocumentSubTypes[doctype]
							});
						} else {
							if (bpNamesUPD.some((it) => it === doctype) && !isAllUPDTypes) {
								selectedBpNames.push({
									type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
									name: 'businessProcess',
									value: edi.i18n.getMessage('documents.fns_upd.type.' + doctype)
								});
							}
							if (bpNamesUKD.some((it) => it === doctype) && !isAllUKDTypes) {
								selectedBpNames.push({
									type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
									name: 'businessProcess',
									value: edi.i18n.getMessage('documents.fns_ukd.type.' + doctype)
								});
							}
							if (bpNamesPricat.some((it) => it === doctype) && !isAllPricatTypes) {
								selectedBpNames.push({
									type: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
									name: 'bpName',
									value: doctype
								});
							}
							if (isSimpleDocTypes) {
								simpleDocTypes.push(doctype);
							}
						}
					});

					if (selectedBpNames.length) {
						selectedBpNames = selectedBpNames.concat(
							simpleDocTypes.map(function (doctype) {
								return {
									type: doctype
								};
							})
						);

						values['typesAndAttrs'] = Ext.encode(selectedBpNames);
						delete values['includeDocTypes'];
					} else {
						values['includeDocTypes'] = Ext.encode(values['includeDocTypes']);
					}
				} else {
					if (values.gridName === 'archived') {
						values['includeDocTypes'].forEach(function (doctype) {
							if (bpNamesUPD.some((it) => it === doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD);
							} else if (bpNamesUKD.some((it) => it === doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD);
							} else if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT);
							} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.DSF_CLIENT);
							} else {
								selectedBpNames.push(doctype);
							}
						});

						values['includeDocTypes'] = Ext.encode([...new Set(selectedBpNames)]);
					} else {
						var tmp = [];
						values['includeDocTypes'].forEach(function (doctype) {
							if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
								tmp.push(edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT);
							} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
								tmp.push(edi.constants.DOCUMENT_TYPES.DSF_CLIENT);
							} else {
								tmp.push(doctype);
							}
						});
						values['includeDocTypes'] = Ext.encode([...new Set(tmp)]);
					}
				}
			}

			if (values['includedFilials']) {
				values['includedFilials'] = Ext.encode(values['includedFilials']);
			}

			if (values['referenceDocType']) {
				values['includeReferenceDocType'] = Ext.encode(values['referenceDocType']);
				delete values['referenceDocType'];
			}
			if (
				values['includeReferenceDocCode'] &&
				values['includeReferenceDocCode'].length &&
				values['includeReferenceDocCode'].some((it) => it === String(edi.constants.APERAK_CODES.CODE_13)) &&
				!values['includeReferenceDocCode'].some((it) => it === String(edi.constants.APERAK_CODES.CODE_34))
			) {
				values['includeReferenceDocCode'].push(String(edi.constants.APERAK_CODES.CODE_34));
				values['includeReferenceDocCode'] = Ext.encode(values['includeReferenceDocCode']);
			}

			if ('aperak' === values.gridName) {
				delete values.gridName;
				delete values.includeStates;
				delete values.includeDocTypes;
				delete values.typesAndAttrs;
			} else {
				delete values.includeReferenceDocCode;
				delete values.includeReferenceDocType;
				delete values.referenceDocNum;
			}

			if ('priceCatalogue' !== values.gridName) {
				delete values.dsfType;
			} else {
				delete values.gridName;
			}

			values = edi.filters.config.generic.convertFieldName(values, 'number');
			if (!Ext.isEmpty(values['number'])) {
				let numbers = values['number'].split(',');
				values['includeNumbers'] = Ext.encode(numbers);
				delete values['number'];
			}

			values = edi.filters.config.generic.convertFieldName(values, 'deliveryGLN');
			values = edi.filters.config.generic.convertFieldName(values, 'referenceDocNum');
			if (Array.isArray(values['referenceDocNum'])) {
				values['includeReferenceDocNums'] = Ext.encode(values['referenceDocNum']);
				delete values['referenceDocNum'];
			}

			if (values['attrNumberVal']) {
				values['attrNumberVal'] = values['attrNumberVal'].replace('attrNumber', values.attribute);
				delete values.attribute;

				values = edi.filters.config.generic.convertFieldName(values, 'attrNumber');
			}

			values['lookupDocsByMembers'] = true;

			values = edi.filters.config.generic.convertFieldName(values, 'orderNumber');
			values = edi.filters.config.generic.convertFieldName(values, 'desadvNumber');
			values = edi.filters.config.generic.convertFieldName(values, 'contractNumber');

			return values;
		}
	},
	ew_invitations: {
		createFormItems: function () {
			const enableUnlimitedDatesSearch = edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD');
			let statusDateRange, creationDateRange;
			var setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
				if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
					dateRangeFields.dateFrom.allowBlank = allowBlank;
					dateRangeFields.dateTo.allowBlank = allowBlank;
				}
			};
			var dateFieldsValidator = function () {
				var statusDateFields, statusDatesSelected, creationDateFields, creationDatesSelected;
				if (statusDateRange && creationDateRange) {
					statusDateFields = statusDateRange.getFields();
					creationDateFields = creationDateRange.getFields();
					statusDatesSelected = statusDateFields.dateTo.getValue() || statusDateFields.dateFrom.getValue();
					creationDatesSelected =
						creationDateFields.dateTo.getValue() || creationDateFields.dateFrom.getValue();

					if (
						(statusDatesSelected && creationDatesSelected) ||
						(!statusDatesSelected && !creationDatesSelected)
					) {
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(statusDateFields, false);
					} else if (creationDatesSelected) {
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(statusDateFields, true);
					} else if (statusDatesSelected) {
						//If statusDate selected
						setAllowBlankForDateRange(statusDateFields, false);
						setAllowBlankForDateRange(creationDateFields, true);
					}
				}
				return true;
			};
			let formItemsMap = {
				receiver: createOrgAutocomplete(
					{
						fieldLabel: edi.i18n.getMessage('document.receiver'),
						name: 'receiver',
						showQtips: true,
						valueField: 'nameId',
						displayField: 'nameWithKpp',
						queryParam: 'receiverName'
					},
					{
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.INVITATIONS.RECEIVERS.GET, {
								gridName: 'outgoing'
							})
						}),
						model: edi.models.getModel('EW_INVITATIONS_ORGS')
					}
				),
				receiverInn: createTextField({
					fieldLabel: edi.i18n.getMessage('column.org.inn'),
					name: 'receiverInn'
				}),
				invitationStatus: createCombo({
					fieldLabel: edi.i18n.getMessage('column.status'),
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.INVITATIONS.STATE.SENT,
							edi.constants.INVITATIONS.STATE.EXPECTATION,
							edi.constants.INVITATIONS.STATE.COMPLETED,
							edi.constants.INVITATIONS.STATE.REJECTED,
							edi.constants.INVITATIONS.STATE.REVOKE,
							edi.constants.INVITATIONS.STATE.EXPIRED
						],
						function (id) {
							var key = 'documents.status.';
							if (
								id === edi.constants.INVITATIONS.STATE.COMPLETED ||
								id === edi.constants.INVITATIONS.STATE.SENT ||
								id === edi.constants.INVITATIONS.STATE.REJECTED ||
								id === edi.constants.INVITATIONS.STATE.REVOKE
							) {
								key += 'invitation.';
							}
							return edi.i18n.getMessage(key + id);
						},
						true
					),
					anyMatch: true,
					name: 'state'
				}),
				invitationProduct: edi.filters.getProduct(),
				creationDateRange: (creationDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.creationDate'),
					validator: dateFieldsValidator,
					period: 'week',
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('invitations.creationDate') + ': ',
							fieldLabel: edi.i18n.getMessage('invitations.creationDate.from'),
							name: 'fromDocTime'
						},
						to: {
							chipTitle: ' — ',
							name: 'toDocTime'
						}
					}
				})),
				statusDateRange: (statusDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.statusDate'),
					validator: dateFieldsValidator,
					period: 'week',
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify.from') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.modify.from'),
							name: 'modifyDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'modifyDateTo'
						}
					}
				}))
			};
			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [[4, 4, 4], 4, [4, 4]],
					items: [
						formItemsMap.invitationStatus,
						formItemsMap.receiver,
						formItemsMap.receiverInn,
						formItemsMap.invitationProduct,
						formItemsMap.creationDateRange,
						formItemsMap.statusDateRange
					]
				})
			];
			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values.receiver) {
				let receiverArr = values.receiver.split('|');
				if (notEmpty(receiverArr[0])) {
					values.receiverName = receiverArr[0];
				}
				if (notEmpty(receiverArr[1])) {
					values.receiverKpp = receiverArr[1];
				}

				delete values.receiver;
			}
			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	ew_invitations_incoming: {
		createFormItems: function () {
			let fromDocTime, modifyDate;
			var setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
				if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
					dateRangeFields.dateFrom.allowBlank = allowBlank;
					dateRangeFields.dateTo.allowBlank = allowBlank;
				}
			};
			var dateFieldsValidator = function () {
				var fromDocTimeDateFields, fromDocTimeDatesSelected, modifyDateFields, modifyDatesSelected;
				if (fromDocTime && modifyDate) {
					fromDocTimeDateFields = fromDocTime.getFields();
					modifyDateFields = modifyDate.getFields();
					fromDocTimeDatesSelected =
						fromDocTimeDateFields.dateTo.getValue() || fromDocTimeDateFields.dateFrom.getValue();
					modifyDatesSelected = modifyDateFields.dateTo.getValue() || modifyDateFields.dateFrom.getValue();

					if (
						(fromDocTimeDatesSelected && modifyDatesSelected) ||
						(!fromDocTimeDatesSelected && !modifyDatesSelected)
					) {
						setAllowBlankForDateRange(fromDocTimeDateFields, false);
						setAllowBlankForDateRange(modifyDateFields, false);
					} else if (fromDocTimeDatesSelected) {
						setAllowBlankForDateRange(fromDocTimeDateFields, false);
						setAllowBlankForDateRange(modifyDateFields, true);
					} else if (modifyDatesSelected) {
						//If statusDate selected
						setAllowBlankForDateRange(modifyDateFields, false);
						setAllowBlankForDateRange(fromDocTimeDateFields, true);
					}
				}
				return true;
			};
			let formItemsMap = {
				invitationStatus: createCombo({
					fieldLabel: edi.i18n.getMessage('column.status'),
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.INVITATIONS.STATE.SENT,
							edi.constants.INVITATIONS.STATE.EXPECTATION,
							edi.constants.INVITATIONS.STATE.COMPLETED,
							edi.constants.INVITATIONS.STATE.REJECTED,
							edi.constants.INVITATIONS.STATE.REVOKE,
							edi.constants.INVITATIONS.STATE.EXPIRED
						],
						function (id) {
							var key = 'documents.status.';
							if (
								id === edi.constants.INVITATIONS.STATE.COMPLETED ||
								id === edi.constants.INVITATIONS.STATE.SENT ||
								id === edi.constants.INVITATIONS.STATE.REJECTED ||
								id === edi.constants.INVITATIONS.STATE.REVOKE
							) {
								key += 'invitation.';
							}
							return edi.i18n.getMessage(key + id);
						},
						true
					),
					anyMatch: true,
					name: 'state'
				}),
				product: edi.filters.getProduct(),
				sender: createOrgAutocomplete(
					{
						fieldLabel: edi.i18n.getMessage('document.sender'),
						name: 'sender',
						showQtips: true,
						valueField: 'nameId',
						displayField: 'nameWithKpp',
						queryParam: 'senderName'
					},
					{
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.INVITATIONS.SENDERS.GET)
						}),
						model: edi.models.getModel('EW_INVITATIONS_ORGS')
					}
				),
				senderInn: createTextField({
					fieldLabel: edi.i18n.getMessage('column.org.inn'),
					name: 'senderInn'
				}),
				fromDocTime: (fromDocTime = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.creationDate'),
					validator: dateFieldsValidator,
					period: 'week',
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('invitations.creationDate') + ': ',
							fieldLabel: edi.i18n.getMessage('invitations.creationDate.from'),
							name: 'fromDocTime'
						},
						to: {
							chipTitle: ' — ',
							name: 'toDocTime'
						}
					}
				})),
				modifyDate: (modifyDate = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.statusDate'),
					validator: dateFieldsValidator,
					period: 'week',
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify.from') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.modify.from'),

							name: 'modifyDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'modifyDateTo'
						}
					}
				}))
			};

			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [[4, 4, 4], 4, [4, 4]],
					items: [
						formItemsMap.invitationStatus,
						formItemsMap.sender,
						formItemsMap.senderInn,
						formItemsMap.product,
						formItemsMap.fromDocTime,
						formItemsMap.modifyDate
					]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values.sender) {
				let senderArr = values.sender.split('|');
				if (notEmpty(senderArr[0])) {
					values.senderName = senderArr[0];
				}
				if (notEmpty(senderArr[1])) {
					values.senderKpp = senderArr[1];
				}

				delete values.sender;
			}
			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	ew_invitations_drafts: {
		createFormItems: function () {
			let formItemsMap = {
				product: edi.filters.getProduct(),
				receiver: createOrgAutocomplete(
					{
						fieldLabel: edi.i18n.getMessage('document.receiver'),
						name: 'receiver',
						showQtips: true,
						valueField: 'nameId',
						displayField: 'nameWithKpp',
						queryParam: 'receiverName'
					},
					{
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.INVITATIONS.RECEIVERS.GET, {
								gridName: 'draft'
							})
						}),
						model: edi.models.getModel('EW_INVITATIONS_ORGS')
					}
				),
				receiverInn: createTextField({
					fieldLabel: edi.i18n.getMessage('column.org.inn'),
					name: 'receiverInn'
				}),
				modifyDate: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.statusDate'),
					period: 'week',
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							allowBlank: false
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify.from') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.modify.from'),
							name: 'modifyDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'modifyDateTo'
						}
					}
				})
			};

			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [[4, 4, 4], 4],
					items: [
						formItemsMap.receiver,
						formItemsMap.receiverInn,
						formItemsMap.product,
						formItemsMap.modifyDate
					]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values.receiver) {
				let receiverArr = values.receiver.split('|');
				if (notEmpty(receiverArr[0])) {
					values.receiverName = receiverArr[0];
				}
				if (notEmpty(receiverArr[1])) {
					values.receiverKpp = receiverArr[1];
				}

				delete values.receiver;
			}
			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	ew_invitations_mass: {
		createFormItems: function () {
			const enableUnlimitedDatesSearch = edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD');
			let statusDateRange, creationDateRange;
			var setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
				if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
					dateRangeFields.dateFrom.allowBlank = allowBlank;
					dateRangeFields.dateTo.allowBlank = allowBlank;
				}
			};
			var dateFieldsValidator = function () {
				var statusDateFields, statusDatesSelected, creationDateFields, creationDatesSelected;
				if (statusDateRange && creationDateRange) {
					statusDateFields = statusDateRange.getFields();
					creationDateFields = creationDateRange.getFields();
					statusDatesSelected = statusDateFields.dateTo.getValue() || statusDateFields.dateFrom.getValue();
					creationDatesSelected =
						creationDateFields.dateTo.getValue() || creationDateFields.dateFrom.getValue();

					if (
						(statusDatesSelected && creationDatesSelected) ||
						(!statusDatesSelected && !creationDatesSelected)
					) {
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(statusDateFields, false);
					} else if (creationDatesSelected) {
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(statusDateFields, true);
					} else if (statusDatesSelected) {
						//If statusDate selected
						setAllowBlankForDateRange(statusDateFields, false);
						setAllowBlankForDateRange(creationDateFields, true);
					}
				}
				return true;
			};
			let formItemsMap = {
				invitationStatus: createCombo({
					fieldLabel: edi.i18n.getMessage('column.status'),
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.INVITATIONS.STATE.SENT,
							edi.constants.INVITATIONS.STATE.COMPLETED,
							edi.constants.INVITATIONS.STATE.DRAFT
						],
						function (id) {
							var key = 'mass.invitation.status.';
							return edi.i18n.getMessage(key + id);
						},
						true
					),
					anyMatch: true,
					name: 'state'
				}),
				invitationProduct: edi.filters.getProduct(),
				creationDateRange: (creationDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.creationDate'),
					validator: dateFieldsValidator,
					period: 'week',
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('invitations.creationDate') + ': ',
							fieldLabel: edi.i18n.getMessage('invitations.creationDate.from'),
							name: 'fromDocTime'
						},
						to: {
							chipTitle: ' — ',
							name: 'toDocTime'
						}
					}
				})),
				statusDateRange: (statusDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('invitations.statusDate'),
					validator: dateFieldsValidator,
					period: 'week',
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify.from') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.modify.from'),
							name: 'modifyDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'modifyDateTo'
						}
					}
				}))
			};
			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [
						[4, 4],
						[4, 4]
					],
					items: [
						formItemsMap.invitationStatus,
						formItemsMap.invitationProduct,
						formItemsMap.creationDateRange,
						formItemsMap.statusDateRange
					]
				})
			];
			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	documents_group_print: {
		createFormItems: function () {
			let formItemsMap = {
				statusField: createFieldContainer({
					fieldLabel: edi.i18n.getMessage('column.report.status'),
					labelAlign: 'top',
					layout: 'column',
					items: [
						createCombo({
							columnWidth: 1,
							name: 'status',
							store: edi.stores.createSimpleInlineStore(
								[
									edi.constants.PRINT_STATUS.WAIT_DECISION,
									edi.constants.REPORT_STATE.GENERATING_ERROR,
									edi.constants.PRINT_STATUS.ORDERED,
									edi.constants.PRINT_STATUS.COMPLETED
								],
								function (id) {
									return edi.i18n.getMessage('report.status.' + id);
								},
								true
							)
						})
					]
				}),
				curentUserField: createFieldContainer({
					fieldLabel: edi.i18n.getMessage('document.group.print.documents.filter.curentUser'),
					labelAlign: 'top',
					layout: 'column',
					getChipValue(fld) {
						return (fld.down('field').getValue() && fld.fieldLabel) || '';
					},
					items: [
						createCheckbox({
							columnWidth: 1,
							inputValue: true,
							checked: true,
							name: 'curentUser'
						})
					]
				}),
				creationDateRange: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					period: 'week',
					maxSearchPeriodDays: 366,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create.from') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.create.from'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				})
			};
			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [[6, 6], 6],
					items: [formItemsMap.statusField, formItemsMap.curentUserField, formItemsMap.creationDateRange]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			values.groupReportType = 'ON_DOCUMENTS';
			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	document_packages: {
		createFormItems: function () {
			let formItemsMap = {
				creationDateRange: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					period: 'week',
					allowBlank: false,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.created.from')
						},
						to: {
							chipTitle: ' — '
						}
					}
				})
			};

			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [6],
					items: [formItemsMap.creationDateRange]
				})
			];
			return {
				formItemsMap,
				items
			};
		}
	},

	ew_archive: {
		createFormItems: function () {
			let formItemsMap = {
				creationDateRange: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					period: 'week',
					maxSearchPeriodDays: 366,
					allowBlank: false,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.created.from'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				})
			};

			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [6],
					items: [formItemsMap.creationDateRange]
				})
			];
			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	ew_approvals: {
		createFormItems: function () {
			let formItemsMap = {
				state: createCombo({
					fieldLabel: edi.i18n.getMessage('ew.documents.approvals.state'),
					columnWidth: 1,
					name: 'state',
					multiSelect: true,
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT,
							edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL,
							edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED,
							edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED,
							edi.constants.APPROVAL_DOCUMENTS.STATE.REVOKED
						],
						function (id) {
							return edi.i18n.getMessage('ew.documents.approvals.state.' + id);
						},
						true
					)
				}),
				routeId: createAutocomplete(
					{
						multiSelect: true,
						showQtips: true,
						valueField: 'id',
						displayField: 'name',
						fieldLabel: edi.i18n.getMessage('ew.documents.approvals.routeName'),
						name: 'routeId',
						queryParam: 'name',
						listeners: {
							beforeQuery: function (plan) {
								let me = this;
								let currentValue = me.getRawValue();
								let selectedValue = me.selection?.data?.[me.displayField];
								if (currentValue === selectedValue) {
									plan.query = '';
								}
								plan.query = plan.query.trim();
							}
						}
					},
					{
						sorters: [],
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.ORG_PROFILE.ROUTES.GET)
						}),
						model: edi.models.getModel('EW_ROUTES')
					}
				),
				title: createTextField({
					fieldLabel: edi.i18n.getMessage('ew.documents.approvals.title'),
					columnWidth: 1,
					name: 'title'
				}),
				creationDateRange: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					period: 'week',
					maxSearchPeriodDays: 366,
					allowBlank: false,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.created.from'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				}),
				docType: createCombo({
					fieldLabel: edi.i18n.getMessage('ew.documents.approvals.docType'),
					columnWidth: 1,
					name: 'docType',
					multiSelect: true,
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
							edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
							edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
							edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
						],
						function (id) {
							return edi.i18n.getMessage('documents.doctype.' + id);
						},
						true
					)
				})
			};

			let items = [
				createFormContainer({
					gap: [32, 16],
					area: [
						[4, 4, 4],
						[6, 4]
					],
					items: [
						formItemsMap.state,
						formItemsMap.routeId,
						formItemsMap.title,
						formItemsMap.creationDateRange,
						formItemsMap.docType
					]
				})
			];
			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values['routeId']) {
				values['routeId'] = Ext.encode(values['routeId']);
			}
			if (values['state']) {
				values['state'] = Ext.encode(values['state']);
			}
			edi.utils.clearEmptyValues(values);
			return values;
		}
	}
});
