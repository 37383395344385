import { createCombo, createSearchTypeSelectionFieldForGrid } from '@Components/fields';
import { createDocumentTypeFilterSelectForGrid } from '@Edi/specialComponents/documentTypeFilterSelect';
import { createProxyConfig } from '@Components/storeComponents';
import { createAutocomplete } from '@Core/specialComponents/fields';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';
import { createPanel } from '@Components/panels';
import { createTextField } from '@UIkit/components/fields';

const filterMethods = {
	createCommonFormItems: () => {
		const includedStates = edi.constants.MODULES.DOCUMENTS.INCLUDED_STATES;
		let docStates = [],
			docDateRange,
			modifyDateRange,
			creationDateRange;

		Object.values(edi.constants.STATE).forEach(function (value) {
			if (
				includedStates.some((it) => it === value) &&
				!edi.constants.MODULES.DOCUMENTS.FILTER_REMOVED_STATES.some((it) => it === value)
			) {
				var searchMap = ['BUSINESS_STATE', value];
				if (
					value === edi.constants.STATE.SENT ||
					value === edi.constants.STATE.WAIT_RECEIVER_DECISION ||
					value === edi.constants.STATE.READ ||
					value === edi.constants.STATE.SIGNED_BY_CONSUMER
				) {
					searchMap.push('DOCUMENTS_FILTER');
				}
				docStates.push({
					id: value,
					name: edi.i18n.getTr(searchMap)
				});
			}
		});

		docStates.push(
			{
				id: 'ACCEPTED',
				name: edi.i18n.getMessage('documents.status.APERAK_CODE_29')
			},
			{
				id: 'ACCEPTED_WITH_COMMENT_13',
				name: edi.i18n.getMessage('documents.status.APERAK_CODE_13')
			},
			{
				id: edi.constants.STATE.MANUAL_PROCESSING,
				name: edi.i18n.getMessage('documents.status.MANUAL_PROCESSING')
			}
		);

		const enableUnlimitedDatesSearch = edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD');

		const setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
			if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
				dateRangeFields.dateFrom.allowBlank = allowBlank;
				dateRangeFields.dateTo.allowBlank = allowBlank;
			}
		};
		const dateFieldsValidator = function () {
			var docDateFields,
				docDatesSelected,
				modifyDateFields,
				modifyDatesSelected,
				creationDateFields,
				creationDatesSelected;
			if (docDateRange && modifyDateRange && creationDateRange) {
				docDateFields = docDateRange.getFields();
				modifyDateFields = modifyDateRange.getFields();
				creationDateFields = creationDateRange.getFields();
				docDatesSelected = docDateFields.dateTo.getValue() || docDateFields.dateFrom.getValue();
				modifyDatesSelected = modifyDateFields.dateTo.getValue() || modifyDateFields.dateFrom.getValue();
				creationDatesSelected = creationDateFields.dateTo.getValue() || creationDateFields.dateFrom.getValue();

				if (
					(docDatesSelected && modifyDatesSelected && creationDatesSelected) ||
					(!docDatesSelected && !modifyDatesSelected && !creationDatesSelected)
				) {
					setAllowBlankForDateRange(docDateFields, false);
					setAllowBlankForDateRange(creationDateFields, false);
					setAllowBlankForDateRange(modifyDateFields, false);
				} else if (creationDatesSelected) {
					setAllowBlankForDateRange(creationDateFields, false);
					setAllowBlankForDateRange(docDateFields, true);
					setAllowBlankForDateRange(modifyDateFields, true);
				} else if (docDatesSelected) {
					setAllowBlankForDateRange(docDateFields, false);
					setAllowBlankForDateRange(creationDateFields, true);
					setAllowBlankForDateRange(modifyDateFields, true);
				} else {
					//If modifyDate selected
					setAllowBlankForDateRange(modifyDateFields, false);
					setAllowBlankForDateRange(docDateFields, true);
					setAllowBlankForDateRange(creationDateFields, true);
				}
			}
			return true;
		};

		const formItemsMap = {
			number: createSearchTypeSelectionFieldForGrid(
				{
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					fieldConf: {
						useFieldLable: edi.i18n.getMessage('documents.list.document.number'),
						name: 'number'
					},
					addEmptyCondition: true
				},
				undefined,
				true
			),
			docType: createDocumentTypeFilterSelectForGrid(),
			includeBusinessStates: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.column.status'),
				emptyText: '',
				name: 'includeBusinessStates',
				store: edi.stores.createInlineStore(docStates, 'SIMPLE', undefined, {
					addEmptyRecord: true
				}),
				multiSelect: true,
				setLastValueAfterTextClear: true,
				anyMatch: true
			}),
			orderNumber: createSearchTypeSelectionFieldForGrid(
				{
					fieldLabel: edi.i18n.getMessage('documents.column.orderNumber'),
					fieldConf: {
						useFieldLable: edi.i18n.getMessage('documents.list.document.number'),
						name: 'orderNumber'
					},
					addEmptyCondition: true
				},
				undefined,
				true
			),
			desadvNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					fieldLabel: edi.i18n.getMessage('documents.column.desadvNumber'),
					fieldConf: {
						name: 'desadvNumber',
						useFieldLable: edi.i18n.getMessage('documents.list.document.number')
					},
					addEmptyCondition: true
				},
				undefined,
				true
			),
			contractNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					fieldLabel: edi.i18n.getMessage('documents.column.contractNumber'),
					fieldConf: {
						//useFieldLable: edi.i18n.getMessage("documents.list.document.number"),
						name: 'contractNumber'
					},
					addEmptyCondition: true
				},
				undefined,
				true
			),
			deliveryGLN: createSearchTypeSelectionFieldForGrid(
				{
					fieldLabel: edi.i18n.getMessage('document.delivery.point.gln'),
					fieldConf: {
						useFieldLable: edi.i18n.getMessage('documents.list.document.number'),
						name: 'deliveryGLN'
					},
					addEmptyCondition: true
				},
				['exact', 'LikeRight'],
				true
			),
			partner: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.form.partner'),
				emptyText: '',
				store: edi.stores.initRelatedOrganizationsFirstEmptyStore(),
				anyMatch: true,
				autoValue: true,
				name: 'partner',
				showQtips: true,
				listeners: {
					change: function (combo, newValue) {
						let selectedOrg = combo.getStore().getById(newValue)?.data;
						let filiationsCombo = formItemsMap.includedFilials;
						let filiationsStore = filiationsCombo?.getStore();

						filiationsCombo?.setVisible(selectedOrg?.hasFilials);
						if (filiationsStore) {
							if (selectedOrg?.hasFilials) {
								filiationsStore.setProxy(
									createProxyConfig({
										type: 'ajax',
										url: edi.utils.compileURL(edi.rest.services.FILIATIONS.GET, {
											headOrgId: selectedOrg.id
										})
									})
								);
								filiationsStore.load();
							} else {
								filiationsCombo.setValue(null);
							}
						}
					}
				}
			}),
			includedFilials: edi.permissions.hasPermission('CLENT_GET_FILIALS')
				? createAutocomplete(
						{
							fieldLabel: edi.i18n.getMessage('documents.partner.filiation'),
							emptyText: '',
							name: 'includedFilials',
							setLastValueAfterTextClear: true,
							multiSelect: true,
							showQtips: true,
							hidden: true
						},
						{
							autoLoad: false,
							proxy: createProxyConfig({
								type: 'ajax',
								url: edi.rest.services.FILIATIONS.GET
							}),
							model: edi.models.getModel('ORGANIZATIONS')
						}
				  )
				: null,
			[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: (docDateRange = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('documents.column.date'),
				validator: dateFieldsValidator,
				maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
						enabledUTC: true
					},
					from: {
						chipTitle: edi.i18n.getMessage('documents.column.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.from'),
						name: 'fromDocTime'
					},
					to: {
						chipTitle: ' — ',
						name: 'toDocTime'
					}
				}
			})),
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: (creationDateRange = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.create'),
				validator: dateFieldsValidator,
				maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
				hideFromPanel: true,
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
						fieldLabel: edi.i18n.getMessage('document.date.create.from'),
						name: 'creationDateFrom'
					},
					to: {
						chipTitle: ' — ',
						name: 'creationDateTo'
					}
				}
			})),
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: (modifyDateRange = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
				validator: dateFieldsValidator,
				maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
				hideFromPanel: true,
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
					},
					from: {
						chipTitle: edi.i18n.getMessage('document.date.modify') + ': ',
						fieldLabel: edi.i18n.getMessage('document.modify.date.from')
					},
					to: {
						chipTitle: ' — '
					}
				}
			})),
			invoiceNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.invoiceNumber'),
					fieldConf: {
						name: 'INVOICE_NUMBER'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			transportDocNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.transportDocNumber'),
					fieldConf: {
						name: 'transportDocNumber'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			factureNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.factureNumber'),
					fieldConf: {
						name: 'factureNumber'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			recadvNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.recadvNumber'),
					fieldConf: {
						name: 'RECEIVING_ADVICE_NUMBER'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			)
		};

		const items = createPanel({
			layout: {
				type: 'grid',
				area: [[4, 4, 4], [4, 4, 4], 4, 6]
			},
			items: [
				formItemsMap.number,
				formItemsMap.docType,
				formItemsMap.includeBusinessStates,
				formItemsMap.orderNumber,
				formItemsMap.deliveryGLN,
				formItemsMap.partner,
				formItemsMap.includedFilials,
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE],
				formItemsMap.desadvNumber,
				formItemsMap.contractNumber,
				formItemsMap.invoiceNumber,
				formItemsMap.transportDocNumber,
				formItemsMap.factureNumber,
				formItemsMap.recadvNumber
			]
		});

		return {
			formItemsMap,
			items
		};
	},
	createArgsCommon: function (values) {
		if (values.ownDocs) {
			values.ownDocs = 'true';
		}
		if (values['includeBusinessStates']) {
			const businessStates = [];
			values['includeBusinessStates'].forEach(function (value) {
				businessStates.push({ businessState: value });
				switch (value) {
					//если в фильтре выбрано SIGNED_BY_CONSUMER добавить еще SIGNED
					case edi.constants.STATE.SIGNED_BY_CONSUMER:
						businessStates.push({ businessState: edi.constants.STATE.SIGNED });
						break;
					//если REJECTED добавить еще REJECTED_BY_RECEIVER
					case edi.constants.STATE.REJECTED:
						businessStates.push({ businessState: edi.constants.STATE.REJECTED_BY_RECEIVER });
						break;
					//если "Принят с комментарием" добавить еще ACCEPTED_WITH_COMMENT_34
					case edi.constants.STATE.ACCEPTED_WITH_COMMENT_13:
						businessStates.push({ businessState: edi.constants.STATE.ACCEPTED_WITH_COMMENT_34 });
						break;
					//если в фильтре выбрано UTOCH добавить еще UTOCH_COMPLETED и UTOCH_SIGNED_BY_CONSUMER
					case edi.constants.STATE.UTOCH:
						businessStates.push(
							{
								businessState: edi.constants.STATE.UTOCH_COMPLETED
							},
							{
								businessState: edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER
							}
						);
						break;
				}
			});
			delete values['includeBusinessStates'];

			if (businessStates.length) {
				values['businessStates'] = Ext.encode(businessStates);
			}
		}

		if (values['includeDocTypes']) {
			//Check if UPD or UKD business process was selected. If so, send selected doctypes & BP-s as separate filter arg
			var bpNamesUPD = Array.isArray(edi.constants.UPD_BP_NAMES_FOR_FILTER)
				? edi.constants.UPD_BP_NAMES_FOR_FILTER
				: [];
			var bpNamesUKD = Array.isArray(edi.constants.UKD_BP_NAMES_FOR_FILTER)
				? edi.constants.UKD_BP_NAMES_FOR_FILTER
				: [];
			var bpNamesPricat = Array.isArray(edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER)
				? edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER
				: [];

			var selectedBpNames = [];

			if (!['priceCatalogue', 'archived', 'aperak'].some((it) => it === values.gridName)) {
				var bpNamesFNS = [],
					simpleDocTypes = [];

				bpNamesFNS = bpNamesFNS.concat(bpNamesUPD);
				bpNamesFNS = bpNamesFNS.concat(bpNamesUKD);
				bpNamesFNS.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT);

				var isAllUPDTypes =
					values['includeDocTypes'].filter(function (elem) {
						return bpNamesUPD.indexOf(elem) > -1;
					}).length === bpNamesUPD.length;
				var isAllUKDTypes =
					values['includeDocTypes'].filter(function (elem) {
						return bpNamesUKD.indexOf(elem) > -1;
					}).length === bpNamesUKD.length;
				var isAllFNSType = bpNamesFNS.every(function (elem) {
					return values['includeDocTypes'].indexOf(elem) > -1;
				});
				var isAllPricatTypes = bpNamesPricat.every(function (elem) {
					return values['includeDocTypes'].indexOf(elem) > -1;
				});

				if (
					isAllFNSType ||
					values['includeDocTypes'].some((it) => it === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT)
				) {
					selectedBpNames.push({
						type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
					});
				}
				if (isAllUPDTypes) {
					selectedBpNames.push({
						type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					});
				}
				if (isAllUKDTypes) {
					selectedBpNames.push({
						type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					});
				}
				if (isAllPricatTypes) {
					selectedBpNames.push({
						type: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
					});
				}

				//Check for UPD and UKD BP-s in doctypes if no general UPD & UKD values are selected
				values['includeDocTypes'].forEach(function (doctype) {
					var isSimpleDocTypes =
						!bpNamesUPD.some((it) => it === doctype) &&
						!bpNamesUKD.some((it) => it === doctype) &&
						!bpNamesPricat.some((it) => it === doctype) &&
						doctype !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT;

					if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
							name: 'subDocType',
							value: edi.stores.data.formalizedDocumentSubTypes[doctype]
						});
					} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
							name: 'subDocType',
							value: edi.stores.data.dsfClientDocumentSubTypes[doctype]
						});
					} else {
						if (bpNamesUPD.some((it) => it === doctype) && !isAllUPDTypes) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
								name: 'businessProcess',
								value: edi.i18n.getMessage('documents.fns_upd.type.' + doctype)
							});
						}
						if (bpNamesUKD.some((it) => it === doctype) && !isAllUKDTypes) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
								name: 'businessProcess',
								value: edi.i18n.getMessage('documents.fns_ukd.type.' + doctype)
							});
						}
						if (bpNamesPricat.some((it) => it === doctype) && !isAllPricatTypes) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
								name: 'bpName',
								value: doctype
							});
						}
						if (isSimpleDocTypes) {
							simpleDocTypes.push(doctype);
						}
					}
				});

				if (selectedBpNames.length) {
					selectedBpNames = selectedBpNames.concat(
						simpleDocTypes.map(function (doctype) {
							return {
								type: doctype
							};
						})
					);

					values['typesAndAttrs'] = Ext.encode(selectedBpNames);
					delete values['includeDocTypes'];
				} else {
					values['includeDocTypes'] = Ext.encode(values['includeDocTypes']);
				}
			} else {
				if (values.gridName === 'archived') {
					values['includeDocTypes'].forEach(function (doctype) {
						if (bpNamesUPD.some((it) => it === doctype)) {
							selectedBpNames.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD);
						} else if (bpNamesUKD.some((it) => it === doctype)) {
							selectedBpNames.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD);
						} else if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
							selectedBpNames.push(edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT);
						} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
							selectedBpNames.push(edi.constants.DOCUMENT_TYPES.DSF_CLIENT);
						} else {
							selectedBpNames.push(doctype);
						}
					});

					values['includeDocTypes'] = Ext.encode([...new Set(selectedBpNames)]);
				} else {
					var tmp = [];
					values['includeDocTypes'].forEach(function (doctype) {
						if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
							tmp.push(edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT);
						} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
							tmp.push(edi.constants.DOCUMENT_TYPES.DSF_CLIENT);
						} else {
							tmp.push(doctype);
						}
					});
					values['includeDocTypes'] = Ext.encode([...new Set(tmp)]);
				}
			}
		}

		if (values['includedFilials']) {
			values['includedFilials'] = Ext.encode(values['includedFilials']);
		}

		if (values['referenceDocType']) {
			values['includeReferenceDocType'] = Ext.encode(values['referenceDocType']);
			delete values['referenceDocType'];
		}
		if (
			values['includeReferenceDocCode'] &&
			values['includeReferenceDocCode'].length &&
			values['includeReferenceDocCode'].some((it) => it === String(edi.constants.APERAK_CODES.CODE_13)) &&
			!values['includeReferenceDocCode'].some((it) => it === String(edi.constants.APERAK_CODES.CODE_34))
		) {
			values['includeReferenceDocCode'].push(String(edi.constants.APERAK_CODES.CODE_34));
			values['includeReferenceDocCode'] = Ext.encode(values['includeReferenceDocCode']);
		}

		if ('aperak' === values.gridName) {
			delete values.gridName;
			delete values.includeStates;
			delete values.includeDocTypes;
			delete values.typesAndAttrs;
		} else {
			delete values.includeReferenceDocCode;
			delete values.includeReferenceDocType;
			delete values.referenceDocNum;
		}

		if ('priceCatalogue' !== values.gridName) {
			delete values.dsfType;
		} else {
			delete values.gridName;
		}

		values = edi.filters.config.generic.convertFieldName(values, 'number');
		if (!Ext.isEmpty(values['number'])) {
			let numbers = Array.isArray(values['number']) ? values['number'] : values['number'].split(',');
			values['includeNumbers'] = Ext.encode(numbers);
			delete values['number'];
		}

		values = edi.filters.config.generic.convertFieldName(values, 'deliveryGLN');
		values = edi.filters.config.generic.convertFieldName(values, 'referenceDocNum');
		if (Array.isArray(values['referenceDocNum'])) {
			values['includeReferenceDocNums'] = Ext.encode(values['referenceDocNum']);
			delete values['referenceDocNum'];
		}

		if (values['attrNumberVal']) {
			values['attrNumberVal'] = values['attrNumberVal'].replace('attrNumber', values.attribute);
			delete values.attribute;

			values = edi.filters.config.generic.convertFieldName(values, 'attrNumber');
		}

		values['lookupDocsByMembers'] = true;

		values = edi.filters.config.generic.convertFieldName(values, 'orderNumber');
		values = edi.filters.config.generic.convertFieldName(values, 'desadvNumber');
		values = edi.filters.config.generic.convertFieldName(values, 'contractNumber');

		values = edi.filters.config.generic.convertFieldName(values, 'INVOICE_NUMBER');
		values = edi.filters.config.generic.convertFieldName(values, 'transportDocNumber');
		values = edi.filters.config.generic.convertFieldName(values, 'factureNumber');
		values = edi.filters.config.generic.convertFieldName(values, 'RECEIVING_ADVICE_NUMBER');

		edi.utils.clearEmptyValues(values);
		return values;
	},
	createWarningAndErrorFormItems: () => {
		const {
			formItemsMap: {
				number,
				[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: docDate,
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: creationDate,
				[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: modifyDate,
				partner,
				includedFilials,
				docType,
				orderNumber,
				deliveryGLN
			}
		} = filterMethods.createCommonFormItems();

		const formItemsMap = {
			number,
			[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: docDate,
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: creationDate,
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: modifyDate,
			partner,
			includedFilials,
			docType,
			orderNumber,
			warningOrErrorText: createTextField({
				fieldLabel: edi.i18n.getMessage('document.warning.error.text'),
				name: 'warningOrErrorText'
			}),
			errorProcessingState: createCombo({
				fieldLabel: edi.i18n.getMessage('document.error.processing.state'),
				store: edi.stores.createSimpleInlineStore(
					['notProcessed', 'processed'],
					(id) => edi.i18n.getMessage(`document.error.processing.state.com.edisoft:general_ui:war:1.0.17.4-RC0`),
					true
				),
				name: 'errorProcessingState',
				forceSelection: false,
				anyMatch: true
			}),
			deliveryPointCode: createSearchTypeSelectionFieldForGrid(
				{
					fieldConf: {
						useFieldLable: edi.i18n.getMessage('column.delivery.point.code'),
						type: 'number',
						name: 'deliveryPointCode'
					}
				},
				['exact', 'LikeRight'],
				true
			),
			deliveryGLN,
			sellerOrderNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					fieldConf: {
						useFieldLable: edi.i18n.getMessage('column.seller.order.number'),
						type: 'number',
						name: 'sellerOrderNumber'
					}
				},
				undefined,
				true
			),
			estimatedDeliveryDate: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('delivery.delivery.date'),
				hideFromPanel: true,
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
						enabledUTC: true
					},
					from: {
						getChipText: function (field) {
							const { dateFrom, dateTo } = field.ownerCt.getFields();
							let txt = `${edi.i18n.getMessage('delivery.delivery.date')}: ${dateFrom.getRawValue()}`;
							if (!dateTo.getValue()) {
								txt += ' —';
							}
							return txt;
						},
						fieldLabel: edi.i18n.getMessage('delivery.delivery.date'),
						name: 'ESTIMATED_DELIVERY_DATEFrom'
					},
					to: {
						getChipText: function (field) {
							const { dateFrom, dateTo } = field.ownerCt.getFields();
							return dateFrom.getValue()
								? ` — ${dateTo.getRawValue()}`
								: `${edi.i18n.getMessage('delivery.delivery.date')}: — ${dateTo.getRawValue()}`;
						},
						name: 'ESTIMATED_DELIVERY_DATETo'
					}
				}
			}),
			invoiceNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.invoiceNumber'),
					fieldConf: {
						name: 'INVOICE_NUMBER'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			transportDocNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.transportDocNumber'),
					fieldConf: {
						name: 'transportDocNumber'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			desadvNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					fieldLabel: edi.i18n.getMessage('documents.column.desadvNumber'),
					fieldConf: {
						name: 'desadvNumber'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			factureNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.factureNumber'),
					fieldConf: {
						name: 'factureNumber'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			),
			recadvNumber: createSearchTypeSelectionFieldForGrid(
				{
					hideFromPanel: true,
					isHiddenFromMenu: function (filterForm) {
						return ['deleted', 'sharing'].includes(filterForm.currentTab);
					},
					fieldLabel: edi.i18n.getMessage('documents.column.recadvNumber'),
					fieldConf: {
						name: 'RECEIVING_ADVICE_NUMBER'
					},
					addEmptyCondition: false
				},
				undefined,
				true
			)
		};

		const items = createPanel({
			layout: {
				type: 'grid',
				area: [[4, 4, 4], [4, 4, 4], [4, 4, 4], [4, 4, 4], 4]
			},
			items: [
				formItemsMap.number,
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
				formItemsMap.partner,
				formItemsMap.docType,
				formItemsMap.warningOrErrorText,
				formItemsMap.orderNumber,
				formItemsMap.errorProcessingState,
				formItemsMap.deliveryPointCode,
				formItemsMap.deliveryGLN,
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE],
				formItemsMap.includedFilials,
				formItemsMap.sellerOrderNumber,
				formItemsMap.estimatedDeliveryDate,
				formItemsMap.invoiceNumber,
				formItemsMap.desadvNumber,
				formItemsMap.transportDocNumber,
				formItemsMap.factureNumber,
				formItemsMap.recadvNumber
			]
		});

		return {
			formItemsMap,
			items
		};
	},
	createArgsWarningAndError: function (values, filterObject) {
		let newValues = filterMethods.createArgsCommon(values);
		// Колонки "Перевозчик" нет в warningAndError
		delete newValues.lookupDocsByMembers;
		newValues = edi.filters.config.generic.convertFieldName(newValues, 'desadvNumber');
		newValues = edi.filters.config.generic.convertFieldName(newValues, 'deliveryPointCode');
		newValues = edi.filters.config.generic.convertFieldName(newValues, 'DELIVERY_POINT_ILN');
		newValues = edi.filters.config.generic.convertFieldName(newValues, 'sellerOrderNumber');
		newValues['outgoing'] = !!filterObject.grid.outgoingOnlySwitch?.getValue();
		return newValues;
	},
	createPackagesFormItems: function () {
		const formItemsMap = {
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('column.creation.date'),
				fieldsConfig: {
					common: {
						invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
					},
					from: {
						chipTitle: edi.i18n.getMessage('column.creation.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.created.to')
					},
					to: {
						chipTitle: ' — '
					}
				}
			})
		};
		const items = [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6]]
				},
				items: [formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]]
			})
		];
		return {
			formItemsMap,
			items
		};
	},
	createArgsPackages: function (values) {
		if (values.dateFrom) {
			values.dateFrom = edi.utils.getSearchTime(values.dateFrom, edi.constants.DATE_FORMAT.SERVER);
		}
		if (values.dateTo) {
			values.dateTo = edi.utils.getSearchTime(
				values.dateTo,
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
		}
		return values;
	},
	createSharingFormItems: function () {
		const types = [];
		let modifyDate;
		Object.values(edi.constants.DOCUMENT_TYPES).forEach(function (value) {
			if (edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES.some((it) => it === value)) {
				types.push({
					id: value,
					name: edi.i18n.getMessage('documents.doctype.' + value)
				});
			}
		});
		const validator = function () {
			const setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
				if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
					dateRangeFields.dateFrom.allowBlank = allowBlank;
					dateRangeFields.dateTo.allowBlank = allowBlank;
				}
			};

			if (modifyDate) {
				const modifyDateFields = modifyDate.getFields();
				const modifyDatesSelected = modifyDateFields.dateTo.getValue() || modifyDateFields.dateFrom.getValue();
				if (modifyDatesSelected) {
					setAllowBlankForDateRange(modifyDateFields, false);
				} else {
					setAllowBlankForDateRange(modifyDateFields, true);
				}
			}
			return true;
		};
		return {
			fromOrgName: createTextField({
				name: 'fromOrgName',
				fieldLabel: edi.i18n.getMessage('documents.column.fromOrg')
			}),
			docId: createTextField({
				name: 'docId',
				fieldLabel: edi.i18n.getMessage('document.docId')
			}),
			toOrgName: createTextField({
				fieldLabel: edi.i18n.getMessage('documents.column.toOrg'),
				name: 'toOrgName'
			}),
			docType: createCombo({
				store: edi.stores.createInlineStore(types, 'SIMPLE', undefined, {
					addEmptyRecord: true
				}),
				fieldLabel: edi.i18n.getMessage('documents.form.type'),
				name: 'docType',
				allowBlank: true,
				forceSelection: true,
				anyMatch: true,
				displayField: 'name',
				valueField: 'id',
				emptyText: edi.i18n.getMessage('form.combo.not.selected')
			}),
			modifyDate: (modifyDate = createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('column.access.grant.date'),
				period: edi.utils.getUserDefaultSearchPeriod(edi.constants.DEFAULT.FILTER.PERIODS.WEEK),
				validator,
				maxSearchPeriodDays: edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD') ? null : 366,
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
						submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
						enabledUTC: true
					},
					from: {
						chipTitle: edi.i18n.getMessage('column.access.grant.date') + ': ',
						fieldLabel: edi.i18n.getMessage('document.access.grant.date.from'),
						name: 'modifyDateFrom'
					},
					to: {
						chipTitle: ' — ',
						name: 'modifyDateTo'
					}
				}
			}))
		};
	},
	createArgsSharing: function (values) {
		edi.utils.clearEmptyValues(values);
		return values;
	}
};

export { filterMethods };
